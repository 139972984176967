import React, { useState } from 'react';
import { ApiCall } from "./../ApiCall.js";
import validateEmail from "./../DataValidation/EmailValidation.js";
import { updatePageMeta } from '../../Redux/siteWideSettingsSlice';
import HtmlImportParser from '../DataManipulation/HtmlImportParser';

const EmailSubscription = ({ content }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subscriptionState, setSubscriptionState] = useState('subscription');
  const [email, setEmail] = useState('');
  const [alreadyCompletedDate, setAlreadyCompletedDate] = useState('');
  const [subscribeOption, setSubscribeOption] = useState('subscribe');
  const [formErrors, setFormErrors] = useState({});
  // dispatch(updatePageMeta("Email Subscription")); // TODO fix meta update for email and mail subscription pages

  const validate = (email) => {
    let errors = {};

    const emailValidation = validateEmail(email);
    if (!emailValidation?.isValid) {
      errors.email = emailValidation?.error;
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const handleSubmit = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();

    const isValid = validate(email);
    if (!isValid) {
      setSubmitLoading(false);
      return;
    }

    var data = {
      "EmailAddress": email,
      "Subscribe": subscribeOption === 'subscribe' ? 1 : 0,
      "Unsubscribe": subscribeOption === 'unsubscribe' ? 1 : 0
    };

    const response = await ApiCall('tq_Contact_EmailSubscription', data, 'TCP');
    const alreadyCompleted = response?.data?.Table?.[0]?.AlreadyCompleted;
    const alreadyCompletedDate = response?.data?.Table1?.[0]?.AlreadyCompletedDate;

    const formattedDate = new Date(alreadyCompletedDate).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });

    if (subscribeOption === 'subscribe' && !alreadyCompleted) {
      setSubscriptionState('thankYou');
    } else if (subscribeOption === 'unsubscribe' && !alreadyCompleted) {
      setSubscriptionState('noEmailThankYou');
    } else if (subscribeOption === 'subscribe' && alreadyCompleted) {
      setSubscriptionState('found');
      setAlreadyCompletedDate(formattedDate);
    } else if (subscribeOption === 'unsubscribe' && alreadyCompleted) {
      setSubscriptionState('noEmailFound');
      setAlreadyCompletedDate(formattedDate);
    }
  };

  return (
    <>
      <HtmlImportParser content={content?.Html || ''} />
      <div className="mt-3 mb-4">
        {subscriptionState === 'thankYou' && (
          <p><h2>Thank you for subscribing to our email newsletter!</h2></p>
        )}

        {subscriptionState === 'found' && (
          <p>Your email address was found in our email subscription list with a date of {alreadyCompletedDate}.<br />You will continue to receive our email newsletter.</p>
        )}

        {subscriptionState === 'noEmailFound' && (
          <p>Your email address was found in our No-Email list with a date of {alreadyCompletedDate}. <br />If you have still been receiving emails, please contact us at <a href='tel:+1-800-619-1110'>800-619-1110</a>.</p>
        )}

        {subscriptionState === 'noEmailThankYou' && (
          <p>Your email address was successfully added to our No-Email list.</p>
        )}

        {subscriptionState === 'error' && (
          <p className="alert alert-danger">You must enter a valid email address!</p>
        )}

        {subscriptionState === 'subscription' && (
          <div className="container EmailSubscription-container">
            <form onSubmit={handleSubmit}>
              <div className="EmailSubscription-radio-div">
                <label className="EmailSubscription-radio-label">
                  <input
                    className="EmailSubscription-radio"
                    type="radio"
                    value="subscribe"
                    checked={subscribeOption === 'subscribe'}
                    onChange={() => setSubscribeOption('subscribe')}
                  />
                  <span className="EmailSubscription-radio-custom"></span>
                  Subscribe
                </label>
                <label className="EmailSubscription-radio-label">
                  <input
                    className="EmailSubscription-radio"
                    type="radio"
                    value="unsubscribe"
                    checked={subscribeOption === 'unsubscribe'}
                    onChange={() => setSubscribeOption('unsubscribe')}
                  />
                  <span className="EmailSubscription-radio-custom"></span>
                  Unsubscribe
                </label>
              </div>
              <div className="form-group row mb-3">
                <label htmlFor='email' className='col-md-3 col-form-label text-md-end EmailSubscription-label'>Email:</label>
                <div className="col-md-8">
                  <input
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`form-control EmailSubscription-input ${formErrors.email ? 'is-invalid' : ''}`}
                    placeholder="Your Email Address.."
                    maxLength="200"
                  // style={{ width: '250px' }}
                  />
                  {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                </div>
              </div>
              <div className="EmailSubscription-submit-div">
                <button type="submit" className="btn btn-primary btn-block EmailSubscription-submit-button">
                  {!submitLoading &&
                    <>
                      <span>SUBMIT</span>
                    </>
                  }
                  {submitLoading &&
                    <>
                      <span style={{ paddingRight: '10px' }}>SUBMIT</span>
                      <i className="fa fa-spinner fa-spin " />
                    </>
                  }
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default EmailSubscription;
