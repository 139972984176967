import { configureStore } from "@reduxjs/toolkit";

import ProductsReducer from '../Redux/productsSlice';
import BillingInputsReducer from '../Redux/billingInputsSlice';
import ShippingInputsReducer from '../Redux/shippingInputsSlice';
import SiteWideSettingsReducer from '../Redux/siteWideSettingsSlice';
import CustomerReducer from '../Redux/customerSlice';
import CartReducer from '../Redux/cartSlice';

const store = configureStore({
  reducer: {
    products: ProductsReducer,
    addBillingInputs: BillingInputsReducer,
    addShippingInputs: ShippingInputsReducer,
    customer: CustomerReducer,
    siteWideSettings: SiteWideSettingsReducer,
    cart: CartReducer,
  },
});

export default store;