// Custom hook for email validation with messages
const useEmailValidation = () => {
  const getEmailValidationState = (email) => {
    if (email.trim() === '') {
      return { isValid: false, message: "Email is required" };
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //alternative format less strict:  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = re.test(String(email).toLowerCase());
    return {
      isValid,
      message: isValid ? "" : "Invalid email format"
    };
  };

  return getEmailValidationState;
};

export default useEmailValidation;
