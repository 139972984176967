import React from 'react';
import backgroundImageWide from "../../Assets/Images/Login-Widescreen.jfif";
import backgroundImageFull from "../../Assets/Images/Login-Fullscreen.jfif";
import "../../styles/_loginShellCustomer.scss";

function LoginBackgroundImg() {
  return (
    <picture>
      <source media="(min-width: 1281px)" srcSet={backgroundImageWide} />
      <source media="(min-width: 768px)" srcSet={backgroundImageFull} />
      <img src="" alt="" className="background-image" />
    </picture>
  );
};


export default LoginBackgroundImg
