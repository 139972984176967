import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { postFullProductDetailsUrl } from '../config/apiconfig';
import axiosHandler from '../components/axiosHandler';
import { encryptUsingAES256Base64, cryptAPIKey } from "../components/Security/crypt";
import Loading from '../components/loading';

const Sage = () => {
  const isLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);
  const [product, setProduct] = useState({
    PrName: '',
    PicLink: '',
    ItemNum: '',
    Description: '',
    Colors: '',
    Dimensions: '',
    ImprintArea: '',
    DecorationMethod: '',
    ProdTime: '',
    Category: '',
    LineName: '',
    Keywords: '',
    Themes: '',
    Prices: [],
    OptionGroups: [],
    MadeInCountry: '',
    AssembledInCountry: '',
    DecoratedInCountry: '',
    PriceIncludes: '',
    SetupCharge: '',
    AdditionalColorCharge: '',
    AdditionalColorRunCharge: '',
    Package: '',
    WeightPerCarton: '',
    UnitsPerCarton: '',
    CartonDimensions: '',
    ShipPointCountry: '',
    ShipPointZip: '',
  });
  const [supplierInfo, setSupplierInfo] = useState({
    SupplierID: '',
    CompanyName: '',
    SupplierLineName: '',
    ContactName: '',
    MailAddress: '',
    MailCity: '',
    MailState: '',
    MailZip: '',
    MailCountry: '',
    ShipAddress: '',
    ShipCity: '',
    ShipState: '',
    ShipZip: '',
    ShipCountry: '',
    Phone: '',
    PhoneTollFree: '',
    Fax: '',
    FaxTollFree: '',
    Email: '',
    Website: '',
    ArtContactName: '',
    ArtContactEmail: '',
    CatalogYear: '',
    CatalogExpireDate: '',
    CatCurrancy: '',
    Comment: '',
    PrefGroup: '',
    PersCSRep: '',
    PersCustNum: '',
    PersSuppNote: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sproductid = searchParams.get('sproductid');
    if (sproductid) {
      fetchProductData(sproductid);
    } else {
      setIsLoading(false);
    }
  }, [location]);

  const fetchProductData = async (id) => {
    try {
      var data = {
        'sageproductid': id
      }
      var requestHeaders = {
        'Content-Type': 'application/json; charset=utf-8',
        'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
      };

      var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));

      const response = await axiosHandler.post(postFullProductDetailsUrl, encryptedData, { headers: requestHeaders, timeout: 30000, });
      const productDetails = JSON.parse(response.data.sageProductDetails);

      setProduct({
        PrName: productDetails.prName,
        PicLink: productDetails.pics[0]?.url || '',
        ItemNum: productDetails.itemNum,
        Description: productDetails.description,
        Colors: productDetails.colors,
        Dimensions: productDetails.dimensions,
        ImprintArea: productDetails.imprintArea,
        DecorationMethod: productDetails.decorationMethod,
        ProdTime: productDetails.prodTime,
        Category: productDetails.category,
        LineName: productDetails.lineName,
        Keywords: productDetails.keywords,
        Themes: productDetails.themes,
        Prices: productDetails.qty.map((qty, index) => ({
          qty,
          prc: productDetails.prc[index],
          net: productDetails.net[index]
        })),
        OptionGroups: productDetails.options || [],
        MadeInCountry: productDetails.madeInCountry,
        AssembledInCountry: productDetails.assembledInCountry,
        DecoratedInCountry: productDetails.decoratedInCountry,
        PriceIncludes: productDetails.priceIncludes,
        SetupCharge: productDetails.setupChg,
        AdditionalColorCharge: productDetails.addClrChg,
        AdditionalColorRunCharge: productDetails.addClrRunChg[0],
        Package: productDetails.package,
        WeightPerCarton: productDetails.weightPerCarton,
        UnitsPerCarton: productDetails.unitsPerCarton,
        CartonDimensions: `${productDetails.cartonL}x${productDetails.cartonW}x${productDetails.cartonH}`,
        ShipPointCountry: productDetails.shipPointCountry,
        ShipPointZip: productDetails.shipPointZip,
      });

      setSupplierInfo({
        SupplierID: productDetails.supplier.suppId,
        CompanyName: productDetails.supplier.coName,
        SupplierLineName: productDetails.supplier.lineName,
        ContactName: productDetails.supplier.contactName,
        MailAddress: productDetails.supplier.mAddr,
        MailCity: productDetails.supplier.mCity,
        MailState: productDetails.supplier.mState,
        MailZip: productDetails.supplier.mZip,
        MailCountry: productDetails.supplier.mCountry,
        ShipAddress: productDetails.supplier.sAddr,
        ShipCity: productDetails.supplier.sCity,
        ShipState: productDetails.supplier.sState,
        ShipZip: productDetails.supplier.sZip,
        ShipCountry: productDetails.supplier.sCountry,
        Phone: productDetails.supplier.tel,
        PhoneTollFree: productDetails.supplier.tollFreeTel,
        Fax: productDetails.supplier.fax,
        FaxTollFree: productDetails.supplier.tollFreeFax,
        Email: productDetails.supplier.email,
        Website: productDetails.supplier.web,
        ArtContactName: productDetails.supplier.artContactName,
        ArtContactEmail: productDetails.supplier.artContactEmail,
        CatalogYear: productDetails.supplier.catYear,
        CatalogExpireDate: productDetails.supplier.catExpOn,
        CatCurrancy: productDetails.supplier.catCurrency,
        Comment: productDetails.supplier.comment,
        PrefGroup: productDetails.supplier.prefGroups,
        PersCSRep: productDetails.supplier.persCsRep,
        PersCustNum: productDetails.supplier.persCustNum,
        PersSuppNote: productDetails.supplier.persSuppNote,
      });
    } catch (error) {
      console.error("Error fetching product data:", error);
      // Handle the error appropriately, e.g., set an error state
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div
        onClick={handleBackClick}
        className="text-theme-primary cursor-pointer mb-3"
        style={{ cursor: 'pointer' }}
      >
        &lt;&lt; Back to Products
      </div>
      <div className="row">
        <div className="col-md-4">
          {product.PicLink ? (
            <img
              className="img-fluid"
              alt={`Example ${product.PrName}`}
              src={product.PicLink.replace('http:', 'https:')}
            />
          ) : (
            <div className="placeholder-glow" style={{ width: '100%', height: '300px', backgroundColor: '#e0e0e0' }}></div>
          )}
        </div>
        <div className="col-md-6 p-2">
          <h1 className="fs-4 border-bottom border-secondary-subtle text-secondary pb-1">{product.PrName}</h1>
          <div className="border-bottom border-secondary-subtle mb-2 pb-1">
            <div className="pt-2 d-inline-block">Item # {product.ItemNum}</div>
          </div>
          <p>{product.Description}</p>
          <p><b>Colors: </b>{product.Colors}</p>
          <p><b>Sizes: </b>{product.Dimensions}</p>
          <div className="row">
            <div className="col-md-5 text-center bg-light">
              {product.Prices && product.Prices.length > 0 && (
                <table className="table table-light">
                  <thead>
                    <tr>
                      <th>QTY</th>
                      <th>Price</th>
                      {isLoggedIn && <th><i>NET</i></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {product.Prices.map((price, index) => (
                      <tr key={index}>
                        <td>{price.qty}</td>
                        <td>{price.prc}</td>
                        {isLoggedIn && <td><i>{price.net}</i></td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="col-md-7 fs-6">
              {product.ImprintArea && <div><b>Imprint Area: </b>{product.ImprintArea}</div>}
              {product.DecorationMethod && <div><b>Imprint Method: </b>{product.DecorationMethod}</div>}
              {product.ProdTime && <div><b>Production Time: </b>{product.ProdTime}</div>}
              <div className="bg-theme-primary text-white fs-5 p-2 mt-3">
                This product cannot be purchased online. Please call 1-800-619-1110 M-F 8AM-5PM to order!
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2 fs-6">
          {product.OptionGroups && product.OptionGroups.map((group, groupIndex) => (
            <div key={groupIndex} className="mb-3">
              <div className="fw-bold">{group.GroupName}</div>
              {group.Options && group.Options.map((option, optionIndex) => (
                <div key={optionIndex} className="mb-2">
                  <div dangerouslySetInnerHTML={{ __html: option.OptionName.replace('(', '<br>(') }} />
                  {option.Prices && option.Prices.map((price, priceIndex) => (
                    <div key={priceIndex}>
                      {price.QTY}/{price.Price === '999.00' ? 'Call for Price' : `$${price.Price}`}
                      {isLoggedIn && <span className="fst-italic d-inline-block ms-1">Net: ${price.Net}</span>}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-3 mb-5">
        <div>Category: {product.Category}</div>
        <div>Line Name: {product.LineName}</div>
        <div>Keywords: {product.Keywords}</div>
        <div>Themes: {product.Themes}</div>
      </div>
      {isLoggedIn && (
        <div className="row mt-5">
          <div className="col-12">
            <h2>Supplier Information</h2>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Supplier ID</td>
                  <td>{supplierInfo.SupplierID}</td>
                </tr>
                <tr>
                  <td>Company Name</td>
                  <td>{supplierInfo.CompanyName}</td>
                </tr>
                <tr>
                  <td>Line Name</td>
                  <td>{supplierInfo.SupplierLineName}</td>
                </tr>
                <tr>
                  <td>Contact Name</td>
                  <td>{supplierInfo.ContactName}</td>
                </tr>
                <tr>
                  <td>Mail Address</td>
                  <td>{supplierInfo.MailAddress}</td>
                </tr>
                <tr>
                  <td>Mail City</td>
                  <td>{supplierInfo.MailCity}</td>
                </tr>
                <tr>
                  <td>Mail State</td>
                  <td>{supplierInfo.MailState}</td>
                </tr>
                <tr>
                  <td>Mail Zip</td>
                  <td>{supplierInfo.MailZip}</td>
                </tr>
                <tr>
                  <td>Mail Country</td>
                  <td>{supplierInfo.MailCountry}</td>
                </tr>
                <tr>
                  <td>Ship Address</td>
                  <td>{supplierInfo.ShipAddress}</td>
                </tr>
                <tr>
                  <td>Ship City</td>
                  <td>{supplierInfo.ShipCity}</td>
                </tr>
                <tr>
                  <td>Ship State</td>
                  <td>{supplierInfo.ShipState}</td>
                </tr>
                <tr>
                  <td>Ship Zip</td>
                  <td>{supplierInfo.ShipZip}</td>
                </tr>
                <tr>
                  <td>Ship Country</td>
                  <td>{supplierInfo.ShipCountry}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{supplierInfo.Phone}</td>
                </tr>
                <tr>
                  <td>Toll Free Phone</td>
                  <td>{supplierInfo.PhoneTollFree}</td>
                </tr>
                <tr>
                  <td>Fax</td>
                  <td>{supplierInfo.Fax}</td>
                </tr>
                <tr>
                  <td>Toll Free Fax</td>
                  <td>{supplierInfo.FaxTollFree}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{supplierInfo.Email}</td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>{supplierInfo.Website}</td>
                </tr>
                <tr>
                  <td>Art Contact Name</td>
                  <td>{supplierInfo.ArtContactName}</td>
                </tr>
                <tr>
                  <td>Art Contact Email</td>
                  <td>{supplierInfo.ArtContactEmail}</td>
                </tr>
                <tr>
                  <td>Catalog Year</td>
                  <td>{supplierInfo.CatalogYear}</td>
                </tr>
                <tr>
                  <td>Catalog Expire Date</td>
                  <td>{supplierInfo.CatalogExpireDate}</td>
                </tr>
                <tr>
                  <td>Catalog Currency</td>
                  <td>{supplierInfo.CatCurrancy}</td>
                </tr>
                <tr>
                  <td>Supplier Comment</td>
                  <td>{supplierInfo.Comment}</td>
                </tr>
                <tr>
                  <td>Preference Group</td>
                  <td>{supplierInfo.PrefGroup}</td>
                </tr>
                <tr>
                  <td>PersCSREp</td>
                  <td>{supplierInfo.PersCSRep}</td>
                </tr>
                <tr>
                  <td>PersCustNum</td>
                  <td>{supplierInfo.PersCustNum}</td>
                </tr>
                <tr>
                  <td>PersSuppNote</td>
                  <td>{supplierInfo.PersSuppNote}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sage;
