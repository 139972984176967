import React, { useEffect } from 'react';
import RhinestoneHat from '../../Assets/Images/535-404.jpg';
import classes from '../../styles/NotFound.module.css';
function NotFound() {
  useEffect(() => {
    document.title = "404 | Triple Crown Products";
  }, []);
  return (
    <div className={`${classes.mainContainer}`}>
      <img className="img img-fluid" src={RhinestoneHat} alt="404" />
      <h1 className={`${classes.mainHeader}`}>
        This is embarrassing!
      </h1>
      <h2>
        Our new rhinestone hat design is not finished yet...
        <br />and we can’t find the page you are looking for.
        <br />Please see the menus above and below or
        <br />call 800-619-1110 to speak to someone who can help.
      </h2>
      <div className={`row ${classes.pagemap}`} >
        <div className="col-3"> </div>
        <div className="col-2">
          <strong>Popular Categories</strong>
          <div><a href="backpacks-embroidered-printed-custom-logo.htm?s=4026" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_15">Backpacks</a></div>
          <div><a href="bags-embroidered-printed-custom-logo.htm?s=4029" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_18">Bags</a></div>
          <div><a href="headwear-embroidered-printed-custom-logo.htm?s=1" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_24">Baseball Hats</a></div>
          <div><a href="basketball-hoops-embroidered-printed-custom-logo.htm?s=4038" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_25">Basketball Hoops</a></div>
          <div><a href="baseball-hats-embroidered-printed-custom-logo.htm?s=6877" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_32">Beanies</a></div>
          <div><a href="coats-jackets-embroidered-printed-custom-logo.htm?s=47" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_117">Coats &amp; Jackets</a></div>
          <div><a href="fleece-embroidered-printed-custom-logo.htm?s=6752" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_198">Fleece</a></div>
          <div><a href="mocks-embroidered-printed-custom-logo.htm?s=4" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_326">Mocks</a></div>
          <div><a href="overalls-embroidered-printed-custom-logo.htm?s=176" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_355">Overalls</a></div>
          <div><a href="pants-1-embroidered-printed-custom-logo.htm?s=131" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_360">Pants</a></div>
          <div><a href="paper-1-embroidered-printed-custom-logo.htm?s=4443" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_361">Paper</a></div>
          <div><a href="polo-sport-shirts-embroidered-printed-custom-logo.htm?s=2" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_390">Polo &amp; Sport Shirts</a></div>
          <div><a href="sweaters-embroidered-printed-custom-logo.htm?s=284" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_502">Sweaters</a></div>
          <div><a href="sweats-fleece-embroidered-printed-custom-logo.htm?s=5" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_503">Sweats</a></div>
          <div><a href="tees-embroidered-printed-custom-logo.htm?s=3" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_516">Tees</a></div>
          <div><a href="turtleneck-embroidered-printed-custom-logo.htm?s=311" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_543">Turtleneck</a></div>
          <div><a href="windshirts-embroidered-printed-custom-logo.htm?s=98" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_567">Windshirts</a></div>
          <div><a href="woven-shirts-embroidered-printed-custom-logo.htm?s=43" id="ContentPlaceHolder1_rptGroups_rptPages_6_aLink_573">Woven Shirts</a></div>
        </div>
        <div className="col-2">
          <strong>Popular Brands</strong>
          <div><a href="carhartt-embroidered-printed-custom-logo.htm?s=680" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_42">Carhartt</a></div>
          <div><a href="columbia-embroidered-printed-custom-logo.htm?s=687" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_51">Columbia</a></div>
          <div><a href="crown-series-embroidered-printed-custom-logo.htm?s=798" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_58">Crown</a></div>
          <div><a href="cutter-buck-embroidered-printed-custom-logo.htm?s=693" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_62">Cutter &amp; Buck</a></div>
          <div><a href="dickies-embroidered-printed-custom-logo.htm?s=695" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_66">Dickies</a></div>
          <div><a href="dri-duck-embroidered-printed-custom-logo.htm?s=698" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_69">DRI-Duck</a></div>
          <div><a href="eddie-bauer-embroidered-printed-custom-logo.htm?s=796" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_74">Eddie Bauer</a></div>
          <div><a href="gildan-embroidered-printed-custom-logo.htm?s=707" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_87">Gildan</a></div>
          <div><a href="jerzees-embroidered-printed-custom-logo.htm?s=718" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_109">Jerzees</a></div>
          <div><a href="ml-kishigo-embroidered-printed-custom-logo.htm?s=1867" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_129">ML Kishigo </a></div>
          <div><a href="nike-golf-embroidered-printed-custom-logo.htm?s=730" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_138">Nike Golf</a></div>
          <div><a href="ogio-embroidered-printed-custom-logo.htm?s=733" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_145">OGIO</a></div>
          <div><a href="radians-embroidered-printed-custom-logo.htm?s=1943" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_161">Radians</a></div>
          <div><a href="reebok-embroidered-printed-custom-logo.htm?s=743" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_166">Reebok</a></div>
          <div><a href="stormtech-embroidered-printed-custom-logo.htm?s=1954" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_185">StormTech</a></div>
          <div><a href="the-north-face-embroidered-printed-custom-logo.htm?s=6771" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_190">The North Face</a></div>
          <div><a href="tough-duck-embroidered-printed-custom-logo.htm?s=1965" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_196">Tough Duck</a></div>
          <div><a href="under-armour-embroidered-printed-custom-logo.htm?s=6765" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_200">Under Armour</a></div>
          <div><a href="van-heusen-embroidered-printed-custom-logo.htm?s=755" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_203">Van Heusen</a></div>
          <div><a href="walls-embroidered-printed-custom-logo.htm?s=759" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_208">Walls</a></div>
          <div><a href="white-bear-2-embroidered-printed-custom-logo.htm?s=797" id="ContentPlaceHolder1_rptGroups_rptPages_8_aLink_212">White Bear</a></div>
        </div>
        <div className="col-2">
          <strong>Featured Stores</strong>
          <div><a href="athletic-apparel-embroidered-printed-custom-logo.htm?s=1883" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_0">Athletic Apparel</a></div>
          <div><a href="best-seller-embroidered-printed-custom-logo.htm?s=6871" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_1">Best Seller</a></div>
          <div><a href="camouflage-embroidered-printed-custom-logo.htm?s=1842" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_2">Camouflage</a></div>
          <div><a href="corporate-embroidered-printed-custom-logo.htm?s=11" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_3">Corporate</a></div>
          <div><a href="flame-resistant-7-embroidered-printed-custom-logo.htm?s=1874" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_4">Flame-Resistant</a></div>
          <div><a href="headwear-embroidered-printed-custom-logo.htm?s=6878" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_5">Headwear</a></div>
          <div><a href="made-in-usa-embroidered-printed-custom-logo.htm?s=1873" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_6">Made in USA</a></div>
          <div><a href="promotional-embroidered-printed-custom-logo.htm?s=14" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_7">Promotional</a></div>
          <div><a href="safety-embroidered-printed-custom-logo.htm?s=13" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_8">Safety</a></div>
          <div><a href="workwear-embroidered-printed-custom-logo.htm?s=12" id="ContentPlaceHolder1_rptGroups_rptPages_7_aLink_9">Workwear</a></div>
        </div>
        <div className="col-3"> </div>
      </div>
    </div>
  )
}

export default NotFound;