import classes from "./../styles/YouAreSpecial.module.css";
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorMessage from '../pages/errors/ErrorMessage';
import { useDispatch } from "react-redux";
import { updatePageMeta } from "../Redux/siteWideSettingsSlice";

function YouAreSpecial() {
  const dispatch = useDispatch();
  dispatch(updatePageMeta("You Are Special"))

  return (
    <ErrorBoundary componentName="YouAreSpecial" fallback={<ErrorMessage />}>
      <div className={classes.mainContainer}>
        <div className={classes.mainWrapper}>
          <h1 className={classes.mainHeader}>You are someone special!</h1>
          <div>
            No other person has ever been born—nor will there ever be one—who is
            just like you. Yes, God created you to be different from everyone
            else, and that’s why you are special.
            <br />
            <br />
            No one else has your point of view, your personality, your character
            or your passions. No one can parent your children, listen to your
            spouse, care for your parents, or siblings, or friends, like you can.
            No one else has your ideas, your talents, your abilities, your
            creativeness or your unique point of view. No, there is truly nobody
            else like you.
          </div>

          <div className={classes.emphasisLine}>
            The Bible tells us that God, in His infinite wisdom and love, created
            you with all your uniqueness.
          </div>
          <div>
            For by him all things were created: things in heaven and on earth,
            visible and invisible...
            <br />
            all things were created by him and for him.
            <br />
            Colossians 1:16
            <br />
            <br />
            For you created my inmost being; you knit me together in my mother’s
            womb.
            <br />
            Psalm 139:13
          </div>

          <div className={classes.emphasisLine}>
            And everything God creates is special.
          </div>

          <div>
            For everything God created is good, and nothing is to be rejected....
            <br />
            1Timothy 4:4
            <br />
            <br />
            I praise you [God] because I am fearfully and wonderfully made; your
            works are wonderful, I know that full well.
            <br />
            Psalm 139:14
            <br />
            <br />
            God created man [and woman] on the earth...Has anything so great as
            this ever happened?
            <br />
            Deuteronomy 4:32
          </div>

          <div className={classes.emphasisLine}>
            God values you, because you were made in His likeness.
          </div>

          <div>
            When God created man, he made him in the likeness of God.
            <br />
            Genesis 5:1
            <br />
            <br />
            ...Your inner self, the unfading beauty of a gentle and quiet
            spirit...is of great worth in God’s sight.
            <br />
            1 Peter 3:4
            <br />
            <br />
            Are not two sparrows sold for a penny? Yet not one of them will fall
            to the ground apart from the will of your Father. And even the very
            hairs of your head are all numbered. So don’t be afraid; you are worth
            more than many sparrows.
            <br />
            Matthew 10:29-31
          </div>

          <div className={classes.emphasisLine}>
            God created you for a special purpose.
          </div>

          <div>
            For we are God’s masterpiece. He has created us anew in Christ Jesus,
            so that we can do the good things he planned for us long ago.
            <br />
            Ephesians 2:10 [NLT]
            <br />
            <br />
            All the days ordained for me were written in your book before one of
            them came to be.
            <br />
            Psalm 139:16
            <br />
            <br />
            Because of Christ, we have received an inheritance from God, for he
            chose us from the beginning, and all things happen just as he decided
            long ago.
            <br />
            Ephesians 1:11 [NLT]
          </div>

          <div className={classes.emphasisLine}>
            God’s love for you is so great, that even when you ignore Him, He
            still reaches out to you.
          </div>

          <div>
            God is so rich in mercy, and he loved us so very much, that even while
            we were dead because of our sins, he gave us life when he raised
            Christ from the dead. It is only by God’s special favor that you have
            been saved!
            <br />
            Ephesians 2:4-5 [NLT]
          </div>

          <div className={classes.emphasisLine}>
            God thinks so much of you that He sent His Son to rescue you. Won’t
            you let Jesus bring you back to God today?
          </div>

          <div>
            This is how God showed his love among us: He sent his one and only Son
            into the world that we might live through him. This is love: not that
            we loved God, but that he loved us and sent his Son as an atoning
            sacrifice for our sins.
            <br />1 John 4:9-10
          </div>

          <div className={classes.emphasisLine}>
            You can come back to God by praying:
          </div>

          <div>
            Oh God, I know I’ve doubted You for a long time. But now I see that
            You created me, You made me unique and special, and You have a purpose
            that only I can fulfill. I want to know You today. I accept Jesus’
            sacrifice for my sins. Thank You for loving me so much. Amen.
            <br />
            <br />
            <br />
            Yet to all who received him, to those who believed in his name, he
            gave the right to become children of God.
            <br />
            John 1:12
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default YouAreSpecial;
