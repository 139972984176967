import React from 'react'

function PasswordStrengthMeter({ password }) {
  const getPasswordStrength = (password) => {
    let strengthScore = 0;
    let strengthLabel = "Poor"; // Default label

    if (password.length > 5) strengthScore += 1;
    if (password.length > 7) strengthScore += 1;
    if (/[a-z]/.test(password)) strengthScore += 1;
    if (/[A-Z]/.test(password)) strengthScore += 1;
    if (/[0-9]/.test(password)) strengthScore += 1;
    if (/[^A-Za-z0-9]/.test(password)) strengthScore += 1;// regex for special characters. The ^ symbol inside the square brackets means "not", so this regex matches any character that is not a letter or a number.

    // Update strengthLabel based on strengthScore
    switch (strengthScore) {
      case 1:
      case 2:
        strengthLabel = "Weak";
        break;
      case 3:
      case 4:
        strengthLabel = "Medium";
        break;
      case 5:
        strengthLabel = "Strong";
        break;
      case 6:
        strengthLabel = "WOW";
        break;
      default:
        strengthLabel = "Empty";
    }

    return { strengthScore, strengthLabel };
  };

  const { strengthScore, strengthLabel } = getPasswordStrength(password);
  const getBackgroundColor = (score) => {
    if (score <= 2) return 'red';
    if (score <= 4) return 'yellow';
    if (score === 5) return 'lightgreen';
    if (score >= 6) return 'darkgreen';
    return 'transparent'; // Default, should not really happen
  };

  const getWidthForScore = (score) => {
    switch (score) {
      case 1: return '5rem';
      case 2: return '5rem';
      case 3: return '10rem';
      case 4: return '10rem';
      case 5: return '15rem';
      case 6: return '20rem';
      default: return '0';
    }
  };
  const strengthBarStyle = {
    width: getWidthForScore(strengthScore),
    height: '10px',
    backgroundColor: getBackgroundColor(strengthScore),
    transition: 'width 0.5s ease-in-out',
  };

  const containerStyle = {
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '5px',
  };

  return (
    <div>
      <div className="password-strength-meter" style={containerStyle}>
        <div style={strengthBarStyle}></div>
      </div>
      <div className="password-strength-label">
        {password && `${strengthLabel}`}
      </div>
    </div>
  );
};
export default PasswordStrengthMeter