import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiCall } from '../../../components/ApiCall';

import "../../../styles/Users.scss";

function Users() {
  const navigate = useNavigate();
  const [Users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    const fetchUsers = async () => {
      const data = {
        'WebsiteID': '1'
      };
      const response = await ApiCall('usp_Webstore_Users', data);
      setUsers(response?.data?.Table || []);
    };
    fetchUsers();
  }, []);

  const handleEditClick = (userCustID) => {
    navigate(`/employee/user/${userCustID}`);
  };

  const filteredUsers = Users.filter(user =>
    user.CustID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.FirstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.LastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.Email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="view-all-users">
      <h1 className="main-header">All Users</h1>
      <div className="search-box">
        <input
          type="text"
          className="search-input"
          placeholder="Search Customers And Or Contacts"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="search-button">
          <i className="fa fa-search"></i>
        </button>
      </div>
      <div className="show-results">
        <label>Show: </label>
        <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={100}>100</option>
        </select>
      </div>
      <table className="users-table">
        <thead>
          <tr>
            <th>FirstName</th>
            <th>LastName</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.length > 0 ? (
            currentUsers.map(user => (
              <tr key={user.WebsiteUserID}>
                <td>{user.FirstName}</td>
                <td>{user.LastName}</td>
                <td>{user.Email}</td>
                <td>
                  <button className="universal-btn view-edit-TcpBtn" onClick={() => handleEditClick(user.WebsiteUserID)}>View/Edit</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No users found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} aria-label="Previous page">
          &laquo;
        </button>
        {[...Array(Math.ceil(filteredUsers.length / itemsPerPage)).keys()].map(number => (
          <button
            key={number}
            aria-label={`Go to page ${number + 1}`}
            className={currentPage === number + 1 ? 'active' : ''}
            onClick={() => paginate(number + 1)}
          >
            {number + 1}
          </button>
        ))}
        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(filteredUsers.length / itemsPerPage)))} aria-label="Next page">
          &raquo;
        </button>
      </div>
    </div>
  );
}

export default Users;
