import React from 'react';

function SignUpTemplate({ resetPasswordURL }) {
  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', backgroundColor: '#f5f5f5', fontFamily: 'Arial, sans-serif', fontSize: '14px', lineHeight: '1.5', color: '#333' }}>
      <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Email Confirmation</h1>
      <p style={{ marginBottom: '20px' }}>Hello,</p>
      <p style={{ marginBottom: '20px' }}>Thank you for signing up. Please click on the link below to confirm your email address. This link expires in 15 minutes.</p>
      <p style={{ marginBottom: '20px' }}><a href={resetPasswordURL} style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', borderRadius: '4px', textDecoration: 'none' }}>Confirm Email</a></p>
      <p style={{ marginBottom: '20px' }}>If you did not sign up for this account, please ignore this email.</p>
      <p style={{ marginBottom: '20px' }}>Thank you,</p>
      <p style={{ marginBottom: '0' }}>TCP Dev Team</p>
    </div>
  );
}

export default SignUpTemplate;
