import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiCall } from '../../../components/ApiCall';
import { useDispatch } from 'react-redux';
import { ProfileLogin } from '../../../Redux/customerSlice';

import "../../../styles/User.scss";

function User() {
  const dispatch = useDispatch();
  const { ID } = useParams();
  const [User, setUser] = useState(null);
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);

  const fetchUser = async (WebsiteUserID, RequestType = null, FirstName = null, LastName = null, Email = null) => {
    var data = {
      'WebsiteID': '1',
      'WebsiteUserID': WebsiteUserID
    };

    if (RequestType !== null) data['RequestType'] = RequestType;
    if (FirstName !== null) data['FirstName'] = FirstName;
    if (LastName !== null) data['LastName'] = LastName;
    if (Email !== null) data['Email'] = Email;
    const response = await ApiCall('usp_Webstore_Users', data);
    return response?.data?.Table[0] || {};
  };

  useEffect(() => {
    const getUserData = async () => {
      const userData = await fetchUser(ID);
      setUser(userData);
    };
    getUserData();
  }, [ID]);

  const toggleEditMode = (e) => {
    e.preventDefault();
    setIsEditMode(!isEditMode);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [id]: value
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const userData = await fetchUser(ID, 'Update', User?.FirstName, User?.LastName, User?.Email);
    setUser(userData);
    setIsEditMode(false);
  };

  const handleBackToUsers = (e) => {
    e.preventDefault();
    navigate('/employee/users');
  };

  const handleLoginAsCustomer = async (e) => {
    e.preventDefault();
    const data = {
      'Email': User?.Email,
      'Password': '',
      'LoginAsUserID': ID
    }
    const response = await ApiCall('usp_Tcp_LoginAuth', data);
    const LoginResults = response?.data?.Table[0]['Results'];
    const UserProfile = response?.data?.Table1;

    if (LoginResults === 'Successful') {
      UserProfile[0]['isLoggedIn'] = true;
      await dispatch(ProfileLogin(UserProfile));
      navigate('/home');
    }
  };

  return (
    <div className="login-card-wrapper">
      <div className="login-card">
        <form>
          <div className="user-form-group">
            <label htmlFor="FirstName">First Name:</label>
            <input
              type="text"
              id="FirstName"
              value={User?.FirstName || ''}
              onChange={handleInputChange}
              readOnly={!isEditMode}
              className="form-control"
            />
          </div>
          <div className="user-form-group">
            <label htmlFor="LastName">Last Name:</label>
            <input
              type="text"
              id="LastName"
              value={User?.LastName || ''}
              onChange={handleInputChange}
              readOnly={!isEditMode}
              className="form-control"
            />
          </div>
          <div className="user-form-group">
            <label htmlFor="Email">Email:</label>
            <input
              type="email"
              id="Email"
              value={User?.Email || ''}
              onChange={handleInputChange}
              readOnly={!isEditMode}
              className="form-control"
            />
          </div>
          {!isEditMode ? (
            <>
              <button className="universal-btn" onClick={toggleEditMode}>Edit</button>
              <button className="universal-btn" onClick={handleBackToUsers}>Back To Users</button>
              <button className="universal-btn login-as-customer-btn" onClick={handleLoginAsCustomer}>Login As Customer</button>
            </>
          ) : (
            <>
              <button className="universal-btn" onClick={handleSave}>Save</button>
              <button className="universal-btn" onClick={toggleEditMode}>Cancel</button>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default User;
