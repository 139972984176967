import React from 'react';

const FormattedTitleCase = ({ value }) => {
  const titleCaseValue = value
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return <>{titleCaseValue} </>;
};

export default FormattedTitleCase;