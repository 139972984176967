import React, { useState, useEffect } from 'react';
import DynamicTabs from './DynamicTabs';
import OrangeCheckBox from '../../Utils/OrangeCheckBox';
import { ApiCall } from '../ApiCall';
import FormattedDate from '../DataManipulation/FormatDate';
import FormattedCurrency from '../DataManipulation/FormatCurrency';
import { useSelector } from 'react-redux';
import Loading from '../loading';
import '../../styles/Orders.scss';

function Orders({ OrderType, isDesktop }) {
  const [Orders, setOrders] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [ordersChecked, setOrdersChecked] = useState(Array(Orders.length).fill(false));
  const [sortedOrders, setSortedOrders] = useState(Orders);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const customerProfile = useSelector(state => state?.customer);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      var data = {
        'OrderType': OrderType,
        'WebsiteId': '1',
        'WebsiteUserId': customerProfile?.websiteUserID,
        'CustID': customerProfile?.account
      };
      const response = await ApiCall('usp_Webstore_Orders', data, 'Tcp');

      setOrders(response?.data?.Table);
      const balanceSum = response?.data?.Table.reduce((acc, order) => acc + parseFloat(order.BalanceDue), 0);
      setGrandTotal(balanceSum);
      setTotal(balanceSum);
      setIsLoading(false);
    };
    fetchOrders();
  }, [OrderType, customerProfile?.websiteUserID, customerProfile?.account]);

  const handlePayInvoice = () => {
    console.log('Pay invoice');
  };

  const handlePayBalance = () => {
    console.log('Pay balance');
  };

  const handleSelectAll = (isChecked) => {
    setIsAllChecked(isChecked);
    setOrdersChecked(Array(Orders.length).fill(isChecked));
  };

  const handleOrderCheck = (index, isChecked) => {
    const newOrdersChecked = [...ordersChecked];
    newOrdersChecked[index] = isChecked;
    setOrdersChecked(newOrdersChecked);
    setIsAllChecked(newOrdersChecked.every(Boolean));
    const selectedOrders = Orders.filter((_, index) => newOrdersChecked[index]);
    if (selectedOrders.length > 0) {
      setTotal(selectedOrders.reduce((acc, Order) => acc + parseFloat(Order.BalanceDue), 0));
    } else {
      setTotal(grandTotal);
    }
  };

  const handleSort = (e) => {
    const sortBy = e.target.value;
    let sorted;

    switch (sortBy) {
      case 'date-oldest':
        sorted = [...sortedOrders].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
        break;
      case 'date-newest':
        sorted = [...sortedOrders].sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
        break;
      default:
        sorted = [...sortedOrders];
    }

    setSortedOrders(sorted);
  };

  return (
    <>
      <DynamicTabs />
      {isLoading && <Loading />}
      {!isLoading && Orders.length === 0 && (
        <div>No orders</div>
      )}
      {!isLoading && Orders.length > 0 && (
        <>
          <div className="select-sort-filter-wrapper">
            <div className="select-invoice">
              <OrangeCheckBox label={"Select All"} onChange={handleSelectAll} isChecked={isAllChecked} />
            </div>
            <div className="sort-filter">
              <div className="sort-orders">
                <span>Sort By:</span>
                <div className="menu w-100">
                  <select className="form-select" onChange={handleSort}>
                    <option value="date-oldest">Oldest</option>
                    <option value="date-newest">Newest</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="orders-container">
            {Orders.map((Order, index) => (
              <div className="order-row" key={Order?.OrderNum}>
                <div className="checkbox-container">
                  <OrangeCheckBox
                    onChange={(isChecked) => handleOrderCheck(index, isChecked)}
                    isChecked={ordersChecked[index]}
                  />
                </div>
                <div className="order-card">
                  <div className="order-details">
                    <p className="order-key">Order Number:</p> <p className="order-value-text">{Order?.OrderNum}</p>
                    <p className="order-key">Order Date:</p> <p className="order-value-text"><FormattedDate date={Order?.OrderDate} dateFormat='MM/dd/yyyy' /></p>
                    <p className="order-key">Order Preview:</p> <p className="order-value-text">{Order?.ItemCount}</p>
                    <p className="order-key">Order Status:</p> <p className="order-value-text">{Order?.OrderStatus}</p>
                  </div>
                  <div className="order-summary">
                    <p className="order-key">Subtotal:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.SubTotal} /></p>
                    <p className="order-key">Shipping:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.Shipping} /></p>
                    <p className="order-key">Taxes:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.Taxes} /></p>
                    <p className="order-key">Payments:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.Payments} /></p>
                    <p className="order-key">Balance Due:</p> <p className="order-value-currency"><FormattedCurrency value={Order?.BalanceDue} /></p>
                  </div>
                  <div className="order-actions">
                    <a className="view-order-btn" href={'./customer/order/' + Order?.Transid}>View Order</a>
                    {parseFloat(Order?.BalanceDue) > 0 && (
                      <div className="pay-invoice-btn" onClick={handlePayInvoice}>Pay Invoice</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="totals-container">
              <p>Account Balance: <FormattedCurrency value={total} /></p>
              {total > 0 && (
                <div className="pay-balance-btn" onClick={handlePayBalance}>Pay Balance</div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
