/**
 * Validates an email address
 * @param {string} email - The email address to validate
 * @returns {Object} An object containing isValid and error properties
*/
export function validateEmail(email) {
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  email = email.trim();
  if (email === '' || email === null || email === undefined) {
    return { isValid: false, error: 'Email is required.' };
  }
  
  if (!EMAIL_REGEX.test(email)) {
    return { isValid: false, error: 'Please enter a valid email address.' };
  }
  
  return { isValid: true, error: '' };
}

export default validateEmail;