import { createSlice } from '@reduxjs/toolkit';
import axiosHandler from '../components/axiosHandler';
import { encryptUsingAES256Base64, cryptAPIKey } from "../components/Security/crypt";
import { postProductsListUrl, postSageConnectProductSearchUrl } from "../config/apiconfig";
import { loadSageProducts } from '../CustomFunctions/MyFunctions';

export const postProductsList = (SelectedAttributes, SortBy, IntPage, IntPerPage, WebsitePageName, OverRideWebsiteID, UseDate, WebOrderID, dtSearchWords, WebsiteUserID, ProductFilter) => async (dispatch) => {
  dispatch(setdtProducts([])); // Clears all stale data for ANY new  fetch (Sort By, Page Change, Show:, etc.)
  dispatch(setdtSageProducts([]));
  dispatch(setLoading(true));

  var productsData = {
    'SelectedAttributes': SelectedAttributes,
    'SortBy': SortBy,
    'IntPage': IntPage,
    'IntPerPage': IntPerPage,
    'WebsitePageName': WebsitePageName,
    'OverRideWebsiteID': OverRideWebsiteID,
    'UseDate': UseDate,
    'WebOrderID': WebOrderID,
    'dtSearchWords': dtSearchWords,
    'WebsiteUserID': WebsiteUserID,
    'ProductFilter': ProductFilter,
  };

  var requestHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
    'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
  };

  var encryptedData = encryptUsingAES256Base64(JSON.stringify(productsData));

  try {
    const response = await axiosHandler.post(postProductsListUrl, encryptedData, { headers: requestHeaders, timeout: 30000, });
    const dtProductIDs = JSON.parse(response.data.dt_product_ids);
    const dtSelectedAttributes = JSON.parse(response.data.dt_selected_attributes);
    const dtAttributes = JSON.parse(response.data.dt_attributes);
    const dtAttributeGroups = JSON.parse(response.data.dt_attribute_groups);
    const dtProducts = JSON.parse(response.data.dt_products);
    const dtBrands = JSON.parse(response.data.dt_brands);
    const dtWebsite = JSON.parse(response.data.dt_website);
    const TotalProducts = JSON.parse(response.data.total_products) ?? 0;
    const CategorySelected = JSON.parse(response.data.category_selected);
    const dtCanonicalURL = JSON.parse(response.data.dt_canonical_url);
    const dtads = JSON.parse(response.data.dt_ads);
    const dtAdLocationParts = JSON.parse(response.data.dt_ad_location_parts);
    const dtAdParts = JSON.parse(response.data.dt_ad_parts);
    const dtCustomMeta = JSON.parse(response.data.dt_custom_meta);
    const CanonicalURL = response.data.canonical_url;

    const GA4 = dtProducts.map(item => ({
      item_id: item.ProductID,
      item_name: item.ProductName,
      currency: "USD",
      price: parseFloat(item.LowestPrice).toFixed(2),
      quantity: item.quantity,
      sku_: item.ProductNumber
    }));

    let SageCatID = "";

    if (Array.isArray(dtSelectedAttributes)) {
      SageCatID = dtSelectedAttributes
        .map((SelectedAttributes) => SelectedAttributes.SageCatID)
        .filter((value) => value !== 0)
        .join(",");
    }

    const CategoryCount = dtAttributes.filter(
      (res) => res.TopProductID > 0
    ).length;

    let responseSage;
    let SageProductsArray = [];
    let totalSageProducts = 0;

    if (SageCatID !== "") {
      const { startNumber, sageProductsCount } = loadSageProducts(IntPage, IntPerPage, TotalProducts);

      var sageProductsData = {
        'categories': SageCatID,
        'keywords': dtSearchWords,
        'quickSearch': dtSearchWords,
        'sort': SortBy,
        'startNum': startNumber,
        'maxRecs': sageProductsCount,
      }

      var encryptedSageData = encryptUsingAES256Base64(JSON.stringify(sageProductsData));
      responseSage = await axiosHandler.post(postSageConnectProductSearchUrl, encryptedSageData, { headers: requestHeaders, timeout: 30000, });
      if (sageProductsCount > 0) {
        const responseData = JSON.parse(responseSage.data.sage_products);
        SageProductsArray = responseData.products;
        totalSageProducts = responseData.totalFound;
      }
    }

    dispatch(setdtProductIDs(dtProductIDs));
    dispatch(setdtSelectedAttributes(dtSelectedAttributes));
    dispatch(setdtAttributes(dtAttributes));
    dispatch(setdtAttributeGroups(dtAttributeGroups));
    dispatch(setdtProducts(dtProducts));
    dispatch(setdtSageProducts(SageProductsArray));
    dispatch(setdtBrands(dtBrands));
    dispatch(setdtWebsite(dtWebsite));
    dispatch(setTotalProducts(TotalProducts));
    dispatch(setTotalSageProducts(totalSageProducts));
    dispatch(setGrandTotalProducts(TotalProducts + totalSageProducts));
    dispatch(setCategorySelected(CategorySelected));
    dispatch(setdtCanonicalURL(dtCanonicalURL));
    dispatch(setdtads(dtads));
    dispatch(setdtAdLocationParts(dtAdLocationParts));
    dispatch(setdtAdParts(dtAdParts));
    dispatch(setdtCustomMeta(dtCustomMeta));
    dispatch(setCanonicalURL(CanonicalURL));
    dispatch(setga4(GA4));
    dispatch(setCategoryCount(CategoryCount));
    dispatch(setSageCatID(SageCatID));
    dispatch(setLoading(false));
  } catch (error) {

  }
}

export const ProductsSlice = createSlice({
  name: 'products',
  initialState: {
    CanonicalURL: '',
    categoryCount: 0,
    CategorySelected: 0,
    dtAdLocationParts: [],
    dtAdParts: [],
    dtads: [],
    dtAttributes: [],
    dtAttributeGroups: [],
    dtBrands: [],
    dtCanonicalURL: [],
    dtCustomMeta: [],
    dtProductIDs: [],
    dtProducts: [],
    dtSageProducts: [],
    dtSelectedAttributes: [],
    dtWebsite: [],
    ga4: [],
    GrandTotalProducts: null,
    loading: true,
    sageCatId: [],
    TotalProducts: null,
    TotalSageProducts: null,
    
    
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setdtProductIDs: (state, action) => {
      state.dtProductIDs = action.payload;
    },
    setdtSelectedAttributes: (state, action) => {
      state.dtSelectedAttributes = action.payload;
    },
    setdtAttributes: (state, action) => {
      state.dtAttributes = action.payload;
    },
    setdtAttributeGroups: (state, action) => {
      state.dtAttributeGroups = action.payload;
    },
    setdtProducts: (state, action) => {
      state.dtProducts = action.payload;
    },
    setdtSageProducts: (state, action) => {
      state.dtSageProducts = action.payload;
    },
    setdtBrands: (state, action) => {
      state.dtBrands = action.payload;
    },
    setdtWebsite: (state, action) => {
      state.dtWebsite = action.payload;
    },
    setTotalProducts: (state, action) => {
      state.TotalProducts = action.payload;
    },
    setTotalSageProducts: (state, action) => {
      state.TotalSageProducts = action.payload;
    },
    setGrandTotalProducts: (state, action) => {
      state.GrandTotalProducts = action.payload;
    },
    setCategorySelected: (state, action) => {
      state.CategorySelected = action.payload;
    },
    setdtCanonicalURL: (state, action) => {
      state.dtCanonicalURL = action.payload;
    },
    setdtads: (state, action) => {
      state.dtads = action.payload;
    },
    setdtAdLocationParts: (state, action) => {
      state.dtAdLocationParts = action.payload;
    },
    setdtAdParts: (state, action) => {
      state.dtAdParts = action.payload;
    },
    setdtCustomMeta: (state, action) => {
      state.dtCustomMeta = action.payload;
    },
    setCanonicalURL: (state, action) => {
      state.CanonicalURL = action.payload;
    },
    setga4: (state, action) => {
      state.ga4 = action.payload;
    },
    setCategoryCount: (state, action) => {
      state.categoryCount = action.payload;
    },
    setSageCatID: (state, action) => {
      state.sageCatId = action.payload;
    },
  }
});

export const
  {
    setCanonicalURL,
    setCategoryCount,
    setCategorySelected,
    setdtAdLocationParts,
    setdtAdParts,
    setdtads,
    setdtAttributeGroups,
    setdtAttributes,
    setdtBrands,
    setdtCanonicalURL,
    setdtCustomMeta,
    setdtProductIDs,
    setdtProducts,
    setdtSageProducts,
    setdtSelectedAttributes,
    setdtWebsite,
    setga4,
    setGrandTotalProducts,
    setLoading,
    setSageCatID,
    setTotalProducts,
    setTotalSageProducts,
    
  } = ProductsSlice.actions;

export default ProductsSlice.reducer;
