import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EmployeeAuth = () => {
  const authTokenEmployee = useSelector((state) => state.siteWideSettings.EmployeeUser.LoggedIn);
  const location = useLocation()

  return (
    authTokenEmployee
      ?
      <Outlet />
      :
      <Navigate to='ae/login' state={{ from: location }} replace />
  )
}
export default EmployeeAuth
