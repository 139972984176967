import React, { useEffect } from 'react'
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorMessage from './errors/ErrorMessage';
import { useSelector, useDispatch } from 'react-redux';
import { updatePageMeta } from '../Redux/siteWideSettingsSlice';
import useContentById from "../Hooks/useContentById.js";
import HtmlEditor from '../components/HtmlEditor';
import '../styles/catalog.scss';
import '../styles/CustomerAssistance.scss';

function Catalog() {
  const dispatch = useDispatch();
  dispatch(updatePageMeta("Catalog"));
  const content = useContentById('76');
  const contentItems = content?.ads;
  
  const Settings = useSelector((state) => state.siteWideSettings);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ErrorBoundary componentName="Catalog" fallback={<ErrorMessage />}>
      <div className="container-ca" id="homejs-ad-location-id-76">
        {contentItems.length > 0
          ? contentItems.map((res, index) => {
            return (
              <div key={index}>
                <HtmlEditor
                  adId={res.AdID}
                  initialHtmlValue={res.Html}
                  loggedIn={Settings?.LoggedIn}
                />
              </div>
            );
          })
          : null}
      </div>
    </ErrorBoundary>
  )
}

export default Catalog