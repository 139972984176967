import React from 'react';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const FormattedDate = ({ date, dateFormat, timeZone = 'UTC' }) => {
  const zonedDate = toZonedTime(new Date(date), timeZone);
  const formattedDate = format(zonedDate, dateFormat);
  return <span>{formattedDate}</span>;
};

export default FormattedDate;
