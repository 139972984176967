import React, { useState, useEffect } from 'react';
import HtmlImportParser from '../../components/DataManipulation/HtmlImportParser';
import { ApiCall } from '../../components/ApiCall';
import ProductItem from '../Products/ProductItem';
import { useSelector } from 'react-redux';

const CurrentSpecial = ({ content = {}, cs = null }) => {
  const [products, setProducts] = useState([]);
  const Settings = useSelector((state) => state.siteWideSettings);  
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const contentParts = content.adParts?.filter(part => part.AdID == cs) || [];
      const allProductIds = contentParts.reduce((acc, part) => {
        const productIds = part.ImagePath.replace("productids:", "")
          .split(".")
          .map(id => id.trim())
          .filter(id => id !== '');
        acc.push(...productIds);
        return acc;
      }, []).join(".");

      const data = {
        "SelectedAttributes": "",
        "SortBy": "Most Popular",
        "intPage": 1,
        "intPerPage": 100, 
        "WebsitePageName": "",
        "OverRideWebsiteID": 0,
        "UseDate": Settings?.activeDate, 
        "SearchWords": "productids:" + allProductIds,
        "WebOrderID": 0,
        "WebsiteUserID": 0,
        "ProductFilter": ""
      };
      
      const response = await ApiCall('tq_Products_V14', data, 'sqlcon');
      if (response.error) {
        console.error(response.error);
        setError("product");
      } else {
        setProducts(response?.data?.Table4 || []);
      }
    };

    fetchProducts();
  }, [content, cs]);

  // Function to render products based on product IDs
  const renderProducts = (imagePath, products) => {
    const productIds = imagePath.replace("productids:", "")
      .split(".")
      .map(id => id.trim())
      .filter(id => id !== '');

    return productIds.map(productId => {
      const product = products.find(p => p.ProductID == productId);
      return product ? (
        <div className="col-6 col-md-4 p-3">
          <ProductItem key={product.ProductID} data={product} />
        </div>
      ) : null;
    });
  };

  // Helper function to render sections items
  const renderSections = (items) => {

    return items.map((item) => (
      <div key={item.AdPartID} className="section-item">
        {item.Html && (
          <div className="section-top-html">{item.Name}
            <HtmlImportParser content={item.Html || ''} />
          </div>
        )}
        {item.ImagePath && error == "product" ? (
          <div className="d-flex justify-content-center p-4">
            <div className="alert alert-danger d-flex align-items-center" role="alert">
              <i className="bi bi-exclamation-circle-fill me-2"></i>
              <span>Unable to load products. Please try again later.</span>
            </div>
          </div>
        ) : (
          <div className="row section-products">
            {renderProducts(item.ImagePath, products)}
          </div>
        )}
        {item.ImageAltText && (
          <div className="section-bottom-html">
            <HtmlImportParser content={item.ImageAltText || ''} />
          </div>
        )}
      </div>
    ));
  };

  return (
    <>
      <div id="current-special-div-html">
        <HtmlImportParser content={content.ads?.find(item => item.AdID == cs)?.Html || ''} />
        {/* // CMS filtered HTML content converted to JSX */}
      </div>
      <div className="current-special-sections">
        {renderSections(content.adParts?.filter(part => part.AdID == cs))}
      </div>
    </>
  );
};

export default CurrentSpecial;