import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeUserLogout, toggleCMSEditButton, toggleTestDate, setTestDateValue } from "../Redux/siteWideSettingsSlice";
import ErrorBoundary from "./ErrorBoundary";
import ErrorMessage from "../pages/errors/ErrorMessage";
import { ApiCall } from "./ApiCall";
import FormattedDate from "./DataManipulation/FormatDate";
import { CurrentApiUrl } from "../config/apiconfig";

function AdminTool() {
  const dispatch = useDispatch();
  const websiteVersion = useSelector((state) => state.siteWideSettings?.websiteVersion?.version);
  const activeDate = useSelector((state) => state.siteWideSettings?.activeDate);
  const pageMeta = useSelector((state) => state.siteWideSettings?.pageMeta);
  const EmployeeUser = useSelector((state) => state.siteWideSettings?.EmployeeUser);

  const handleSave = async () => {
    let title = document.getElementById("customTitleInput").value;
    const description = document.getElementById("customMetaDescriptionInput").value;

    // TODO make the title always show that it includes '| Triple Crown Products' but strip it and don't let
    // it be saved to the database. 
    // if (title === null || title === '') {
    //   title = `Triple Crown Products`;
    // } else if (!title.includes('| Triple Crown Products')) {
    //   title = `${title} | Triple Crown Products`;
    // }

    var data = {
      'Title': title,
      'MetaKeywords': '',
      'MetaDescription': description,
      'WebsiteID': 1
    }

    if (pageMeta?.seoFor === 'Selected Attributes') {
      data['SelectedAttributes'] = pageMeta?.seoProperty;
    } else if (pageMeta?.seoFor === 'ProductID') {
      data['ProductID'] = pageMeta?.seoProperty;
    } else if (pageMeta?.seoFor === 'Url Path') {
      data['RawURL'] = pageMeta?.seoProperty;
    } else {
      return;
    }

    await ApiCall('usp_SEO_Edit', data);
  };

  return (
    <ErrorBoundary componentName="AdminTool" fallback={<ErrorMessage />}>
      <div className="container-fluid mt-3 admin-tool-container">
        <div className="row mb-2 align-items-center">
          <label className="col-sm-3 col-form-label text-white text-end">{pageMeta?.seoFor}:</label>
          <div className="col-sm-9">
            <p className="form-control-plaintext text-white mb-0">{pageMeta?.seoProperty}</p>
          </div>
        </div>
        <div className="row mb-2">
          <label htmlFor="customTitleInput" className="col-sm-3 col-form-label text-white text-end">Custom Title:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="customTitleInput"
              defaultValue={pageMeta?.customTitle}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              data-form-type="other"
              data-lpignore="true"
            />
          </div>
        </div>
        <div className="row mb-2">
          <label htmlFor="customMetaDescriptionInput" className="col-sm-3 col-form-label text-white text-end">Custom Meta Description:</label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="customMetaDescriptionInput"
              defaultValue={pageMeta?.customDescription}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              data-form-type="other"
              data-lpignore="true"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-9 offset-sm-3">
            <button className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
        <div className="row mb-2 align-items-center">
          <label className="col-sm-3 col-form-label text-white text-end">Logged In As:</label>
          <div className="col-sm-9">
            {EmployeeUser?.LoggedIn ? (
              <div className="d-flex align-items-center">
                <span className="me-2 text-white">{EmployeeUser?.Name}</span>
                <button className="btn btn-secondary btn-sm" onClick={() => dispatch(EmployeeUserLogout())}>
                  Log Out
                </button>
              </div>
            ) : (
              <p className="form-control-plaintext text-white mb-0">Not Logged In</p>
            )}
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-sm-3 col-form-label text-white text-end">Show CMS Edit Button:</label>
          <div className="col-sm-9">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="cmsEditButton"
                checked={EmployeeUser?.CMSEditButton}
                onChange={(e) => dispatch(toggleCMSEditButton(e.target.checked))}
              />
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <label htmlFor="testDate" className="col-sm-3 col-form-label text-white text-end">Select Test Date:</label>
          <div className="col-sm-9">
            <input
              type="date"
              className="form-control"
              id="testDate"
              value={EmployeeUser?.TestDate}
              onChange={(e) => dispatch(setTestDateValue(e.target.value))}
            />
          </div>
        </div>
        <div className="row mb-2 align-items-center">
          <label className="col-sm-3 col-form-label text-white text-end">Date Used:</label>
          <div className="col-sm-9">
            <p className="form-control-plaintext text-white mb-0">
              <FormattedDate date={activeDate} dateFormat='MM/dd/yyyy' />
            </p>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-sm-3 col-form-label text-white text-end">Activate Test Date:</label>
          <div className="col-sm-9">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="testDateActive"
                checked={EmployeeUser?.TestDateActive}
                onChange={(e) => dispatch(toggleTestDate(e.target.checked))}
              />
            </div>
          </div>
        </div>
        <div className="row mb-2 align-items-center">
          <label className="col-sm-3 col-form-label text-white text-end">API URL:</label>
          <div className="col-sm-9">
            <p className="form-control-plaintext text-white mb-0">{CurrentApiUrl}</p>
          </div>
        </div>
        <div className="row mb-2 align-items-center">
          <label className="col-sm-3 col-form-label text-white text-end">Website Version:</label>
          <div className="col-sm-9">
            <p className="form-control-plaintext text-white mb-0">{websiteVersion}</p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default AdminTool;
