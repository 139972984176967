import React from 'react';
import '../../styles/FiveDayShip.scss';

function FiveDayShip() {
  return (
    <div className="banner-effect banner-effect--five-day-ship">
      <button className="banner-effect__button">
        5-DAY SHIP
      </button>
    </div>
  );
}

export default FiveDayShip;
