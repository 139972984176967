import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearEmployeeInfoProfile, clearEmployee, setShowNavbar, setShowFooter, updatePageMeta } from '../../Redux/siteWideSettingsSlice';
import ErrorBoundary from "../../components/ErrorBoundary";
import ErrorMessage from "../errors/ErrorMessage";
import Loading from "../../components/loading";
import AdminTool from "../../components/AdminTool";
import EmployeeInfoHeaderImg from "../../Assets/Images/EmployeeInfoHeader.jpg";
import EnglishImg from "../../Assets/English.jpg";
import SpanishImg from "../../Assets/Espanol.jpg";

import "../../styles/_employeeinfo.scss";

const EmployeeInfoSpanish = lazy(() =>
  import("../../components/EmployeeInfo/InfoSpanish.js")
);
const EmployeeInfoEnglish = lazy(() =>
  import("../../components/EmployeeInfo/InfoEnglish.js")
);

function EmployeeInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const isLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);
  const selectEnglish = () => { setSelectedLanguage('eng'); };
  const selectSpanish = () => { setSelectedLanguage('spa'); };

  dispatch(updatePageMeta("Employee Information"));
  //TODO: consider Mobile VIew Styling and rendering difreences
  // const isMobile = window.innerWidth <= 767;

  // Set showNavbar to false when the component mounts
  useEffect(() => {
    dispatch(setShowNavbar(false));
    dispatch(setShowFooter(false));
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(clearEmployeeInfoProfile()); // clear the employee authentication status
    navigate('/employeeinformation');
  };

  return (
    <ErrorBoundary componentName="EmployeesInfo" fallback={<ErrorMessage />}>
      <Suspense fallback={<Loading />}>
        <div className="logout-button-container">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
        <div className="employee-header-img-div">
          <img src={EmployeeInfoHeaderImg} alt="Employee Info Header" />
        </div>

        <div className="language-selection-container">
          <div className="language-img-container">
            <img src={EnglishImg} alt="English" onClick={selectEnglish} className="language-img" />
          </div>
          <div className="language-img-container">
            <img src={SpanishImg} alt="Spanish" onClick={selectSpanish} className="language-img" />
          </div>
        </div>
        <div className="employees-information-div">
          {selectedLanguage === 'eng' && <EmployeeInfoEnglish />}
          {selectedLanguage === 'spa' && <EmployeeInfoSpanish />}
        </div>
        {isLoggedIn && (
          <div id="AdminToolStyling">
            <AdminTool />
          </div>
        )}
      </Suspense>
    </ErrorBoundary>
  )
}

export default EmployeeInfo;
