import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { postLogError } from '../config/apiconfig';
import { cryptAPIKey, encryptUsingAES256Base64 } from './Security/crypt';
import { postSendEmailAsync } from "../Redux/siteWideSettingsSlice";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  logErrorToDatabase(error, errorInfo) {
    try {
      const { auth, componentName } = this.props;
      const UserId = auth?.UserId || 'Unknown';
      const stackTrace = errorInfo ? errorInfo.componentStack : error.stack;
      const host = window.location?.hostname;
      const page = componentName || 'Unknown';
      const packageJson = require('../../package.json');
      const appVersion = packageJson.version;

      const data = {
        Host: host,
        ApplicationName: 'TCP React',
        UserId: UserId,
        ErrorMessage: error.toString().substring(0, 400),
        ErrorStack: stackTrace.substring(0, 500),
        URL: window.location.href,
        Page: page,
        AppVersion: appVersion,
      };

      var requestHeaders = {
        'Content-Type': 'application/json; charset=utf-8',
        'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
      };

      var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
      axios.post(postLogError, encryptedData, { headers: requestHeaders, });
    } catch (error) {
      this.props.dispatch(postSendEmailAsync('devteam@triplecrownproducts.com', '', 'Error logging TcpReact error to datebase', error.toString(), 'noreply@triplecrownproducts.com'));
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    this.logErrorToDatabase(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      //TODO add secondary fall back message
      return this.props.fallback;
    }

    return this.props.children;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.siteWideSettings.userId,
  };
};

export default connect(mapStateToProps)(ErrorBoundary);
