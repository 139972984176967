import React from 'react';
import '../../styles/NewBanner.scss';

function NewBanner() {
  return (
    <div className="banner-effect banner-effect--new">
      <button className="banner-effect__button">
        NEW
      </button>
    </div>
  );
}

export default NewBanner;
