import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProfileAddress } from "../../Redux/customerSlice";
import DynamicTabs from "./DynamicTabs";
import Loading from '../loading';
import { ApiCall } from "../ApiCall";
import FormattedTitleCase from "../DataManipulation/FormatTitleCase";
import "../../../src/styles/CustomerProfile.scss";

const ProfileCustomer = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const customerProfile = useSelector((state) => state?.customer);
  const [formData, setFormData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [States, setStates] = useState([]);
  const [Countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const data = { 'EmailAddress': customerProfile?.email };
      const response = await ApiCall('usp_CustomerProfile', data, 'Tcp');
      dispatch(ProfileAddress(response?.data?.Table));
      const profileData = response?.data?.Table[0] || {};
      const states = response?.data?.Table1 || {};
      const countries = response?.data?.Table2 || {};
      setFormData(profileData);
      setOriginalData(profileData);
      setStates(states);
      setCountries(countries);
      setIsLoading(false);
    };

    fetchProfile();
  }, [dispatch, customerProfile?.email]);

  const handleEdit = (status) => {
    setIsEditMode(status);
    if (!status) {
      setFormData(originalData);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const data = {
      'EditProfile': '1',
      'EmailAddress': customerProfile?.email,
      'FirstName': formData?.FirstName,
      'LastName': formData?.LastName,
      'Address1': formData?.Addr1,
      'Address2': formData?.Addr2,
      'City': formData?.City,
      'Region': formData?.Region,
      'Country': formData?.Country,
      'PostalCode': formData?.PostalCode,
      'Phone': formData?.Phone,
    };
    const response = await ApiCall('usp_CustomerProfile', data, 'Tcp');
    if (response?.data?.Table) {
      dispatch(ProfileAddress(response?.data?.Table));
      const profileData = response?.data?.Table[0] || {};
      setFormData(profileData);
      setOriginalData(profileData);
    } else {
      setFormData({});
      setOriginalData({});
    }
    setIsEditMode(false);
    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  return (
    <>
      <div className="tabsAndPages-wrapper">
        <DynamicTabs />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="profile-customer container">
          <form>
            <div className="mb-1 row align-items-center">
              <label htmlFor="FirstName" className="col-form-label col-md-2 text-md-end text-nowrap">First Name:</label>
              <div className="col-md-4">
                <input
                  type="text"
                  id="FirstName"
                  value={formData.FirstName || ""}
                  readOnly={!isEditMode}
                  className={`form-control ${!isEditMode ? "read-only" : ""}`}
                  tabIndex={isEditMode ? 0 : -1}
                  onChange={handleChange}
                />
              </div>
              <label htmlFor="LastName" className="col-form-label col-md-2 text-md-end text-nowrap">Last Name:</label>
              <div className="col-md-4">
                <input
                  type="text"
                  id="LastName"
                  value={formData.LastName || ""}
                  readOnly={!isEditMode}
                  className={`form-control ${!isEditMode ? "read-only" : ""}`}
                  tabIndex={isEditMode ? 0 : -1}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-1 row align-items-center">
              <label htmlFor="Addr1" className="col-form-label col-md-2 text-md-end text-nowrap">Address 1:</label>
              <div className="col-md-10">
                <input
                  type="text"
                  id="Addr1"
                  value={formData.Addr1 || ""}
                  readOnly={!isEditMode}
                  className={`form-control ${!isEditMode ? "read-only" : ""}`}
                  tabIndex={isEditMode ? 0 : -1}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-1 row align-items-center">
              <label htmlFor="Addr2" className="col-form-label col-md-2 text-md-end text-nowrap">Address 2:</label>
              <div className="col-md-10">
                <input
                  type="text"
                  id="Addr2"
                  value={formData.Addr2 || ""}
                  readOnly={!isEditMode}
                  className={`form-control ${!isEditMode ? "read-only" : ""}`}
                  tabIndex={isEditMode ? 0 : -1}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-1 row align-items-center">
              <label htmlFor="City" className="col-form-label col-md-2 text-md-end text-nowrap">City:</label>
              <div className="col-md-10">
                <input
                  type="text"
                  id="City"
                  value={formData.City || ""}
                  readOnly={!isEditMode}
                  className={`form-control ${!isEditMode ? "read-only" : ""}`}
                  tabIndex={isEditMode ? 0 : -1}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-1 row align-items-center">
              <label htmlFor="Country" className="col-form-label col-md-2 text-md-end text-nowrap">Country:</label>
              <div className="col-md-4">
                <select
                  id="Country"
                  value={formData.Country || ""}
                  disabled={!isEditMode}
                  className="form-select"
                  onChange={handleChange}
                >
                  <option value="">Select a country</option>
                  {Countries.map((Country) => (
                    <option key={Country.CountryNumeric} value={Country.CountryCode2}><FormattedTitleCase value={Country.CountryName} /></option>
                  ))}
                </select>
              </div>
              <label htmlFor="Region" className="col-form-label col-md-1 text-md-end text-nowrap">State:</label>
              <div className="col-md-2">
                <select
                  id="Region"
                  value={formData.Region || ""}
                  disabled={!isEditMode}
                  className="form-select"
                  onChange={handleChange}
                >
                  <option value="">Select a state</option>
                  {States.map((State) => (
                    <option key={State.StateAbbreviation} value={State.StateAbbreviation}>{State.StateAbbreviation}</option>
                  ))}
                </select>
              </div>
              <label htmlFor="PostalCode" className="col-form-label col-md-1 text-md-end text-nowrap">Zip:</label>
              <div className="col-md-2">
                <input
                  type="text"
                  id="PostalCode"
                  value={formData.PostalCode || ""}
                  readOnly={!isEditMode}
                  className={`form-control ${!isEditMode ? "read-only" : ""}`}
                  tabIndex={isEditMode ? 0 : -1}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mb-1 row align-items-center">
              <label htmlFor="Phone" className="col-form-label col-md-2 text-md-end text-nowrap">Phone:</label>
              <div className="col-md-3">
                <input
                  type="text"
                  id="Phone"
                  value={formData.Phone || ""}
                  readOnly={!isEditMode}
                  maxLength="21"
                  className={`form-control ${!isEditMode ? "read-only" : ""}`}
                  tabIndex={isEditMode ? 0 : -1}
                  onChange={handleChange}
                />
              </div>
              <label htmlFor="Email" className="col-form-label col-md-1 text-md-end text-nowrap">Email:</label>
              <div className="col-md-6">
                <input
                  type="email"
                  id="Email"
                  value={formData.EmailAddress || ""}
                  readOnly={!isEditMode}
                  className="form-control read-only"
                  tabIndex={-1}
                />
              </div>
            </div>
            <div className="mb-2 row align-items-center">
              <label htmlFor="AccountNumber" className="col-form-label col-md-2 text-md-end text-nowrap">Account Number:</label>
              <div className="col-md-10">
                <input
                  type="text"
                  id="AccountNumber"
                  value={customerProfile?.account || ""}
                  readOnly={true}
                  className="form-control read-only"
                  tabIndex={-1}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              {!isEditMode ? (
                <button type="button" className="btn btn-primary btn-width" onClick={() => handleEdit(true)}>Edit</button>
              ) : (
                <>
                  <button type="button" className="btn btn-primary btn-width me-2" onClick={() => handleEdit(false)}>Cancel</button>
                  <button type="button" className="btn btn-primary btn-width" onClick={handleSave}>Update</button>
                </>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ProfileCustomer;
