import { createSlice } from "@reduxjs/toolkit"

const shippingInputsSlice = createSlice({
  name: "shippingInputs",
  initialState: [
    { id: 1, shippingOrganizationName: "TestName1", shippingContactName: "TestName1", shippingCountry: "TestName1", shippingAddressA: "TestName1", shippingAddressB: "TestName1", shippingCity: "TestName1", shippingState: "TestName1", shippingPostalCode: "TestName1", shippingPhone: "TestName1", shippingEmail: "TestName1", shippingWebsite: "TestName1" },
    // { id: 2, shippingOrganizationName: "TestName2" }
  ],
  
  reducers: {
    addShippingInputs: (state, action) => {
      
      const newShippingInputs = {
        // id: Date.now(),
        shippingOrganizationName: action.payload.shippingOrganizationName,
        shippingContactName: action.payload.shippingContactName,
        shippingCountry: action.payload.shippingCountry,
        shippingAddressA: action.payload.shippingAddressA,
        shippingAddressB: action.payload.shippingAddressB,
        shippingCity: action.payload.shippingCity,
        shippingState: action.payload.shippingState,
        shippingPostalCode: action.payload.shippingPostalCode,
        shippingPhone: action.payload.shippingPhone,
        shippingEmail: action.payload.shippingEmail,
        shippingWebsite: action.payload.shippingWebsite,
      };
      // state.push(newShippingInputs);
      return newShippingInputs;
    },
  },
});

export const {
  addShippingInputs,
} = shippingInputsSlice.actions;
export default shippingInputsSlice.reducer;