import React from 'react';
import parse from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

// HTML to React attribute mapping
const ATTR_MAPPING = {
  'class': 'className',
  'frameborder': 'frameBorder',
  'allowfullscreen': 'allowFullScreen',
  'marginwidth': 'marginWidth',
  'marginheight': 'marginHeight',
  'scrolling': 'scrolling',
  'srcdoc': 'srcDoc',
  'crossorigin': 'crossOrigin',
  'tabindex': 'tabIndex',
  'maxlength': 'maxLength',
  'readonly': 'readOnly',
  'cellspacing': 'cellSpacing',
  'cellpadding': 'cellPadding',
  'rowspan': 'rowSpan',
  'colspan': 'colSpan',
  'for': 'htmlFor',
};

// Convert HTML attributes to React props
const convertAttributes = (attribs) => {
  const { style, ...rest } = attribs;
  const converted = {};

  // Convert style string to object
  const styleObject = style ? 
    style.split(';').reduce((styleAcc, style) => {
      const [property, value] = style.split(':').map(s => s.trim());
      if (property && value) {
        const camelCaseProperty = property.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        styleAcc[camelCaseProperty] = value;
      }
      return styleAcc;
    }, {}) : {};

  // Convert all other attributes
  Object.entries(rest).forEach(([key, value]) => {
    // Convert to camelCase if it's in our mapping
    const reactKey = ATTR_MAPPING[key.toLowerCase()] || key;
    converted[reactKey] = value;
  });

  return { converted, styleObject };
};

function HtmlImportParser({content}) {
  const ImageUrl = useSelector((state) => state?.siteWideSettings?.imageServerUrl);

  return parse(content, {
    replace: ({ name, attribs }) => {
      if ((name === 'img' || name === 'iframe') && attribs) {
        const { converted, styleObject } = convertAttributes(attribs);

        if (name === 'img') {
          return (
            <LazyLoadImage
              {...converted}
              src={attribs['data-src'] || attribs.src} // TODO: remove data-src attribute from HTML in ads 54-57 after testing and live deployment. i.e. when TcpMobile no longer uses it.
              placeholderSrc={`${ImageUrl}adfiles/8167-TCPLoading_Optimized.gif`}
              style={styleObject}
            />
          );
        } else { // iframe
          return (
            <iframe
              {...converted}
              title={attribs.title || "Embedded Content"}
              style={styleObject}
            />
          );
        }
      }
    }
  });
}

export default HtmlImportParser;