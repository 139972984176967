import React from "react";

import AELogin from "../../../components/AddEdit/AELogin";
import { useSelector } from "react-redux";



function AEHome() {
  const isStaffLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);


  return (
    <div className="aeHome" style={{display: 'flex', justifyContent: 'center'}}>
      {
        isStaffLoggedIn
          ? <div className="theme-success-message" style={{maxWidth: '30%', }}>SUCCESS: You are logged in</div>
          : <AELogin />
      }
    </div>
  );
}

export default AEHome;
