import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from "../ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import useEmailFormatValidator from "../../Hooks/useEmailValidation";
import GreyCheckbox from "../../Utils/GreyCheckbox";
import { ApiCall } from "../ApiCall";
import { ProfileLogin, Accounts } from '../../Redux/customerSlice';
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";

function LoginCustomer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getEmailValidationState = useEmailFormatValidator();
  const [email, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [disableFields, setdisableFields] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const emailValidationState = getEmailValidationState(email);
  const [Results, setResults] = useState('');
  const [ResultsMessage, setResultsMessage] = useState('');
  const EmployeeLoggedIn = useSelector((state) => state.siteWideSettings?.EmployeeUser?.LoggedIn);
  dispatch(updatePageMeta("Customer Login"));

const handleLogin = async (event) => {
    event.preventDefault();
    setLoginLoading(true);
    setdisableFields(true);

    setEmailError("");
    setPasswordError("");
    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('password').style.border = '1px solid #ced4da';

    if (!emailValidationState.isValid) {
      document.getElementById('email').style.border = '1px solid red';
      setEmailError(emailValidationState.message);
      return;
    }
    if (password === '') {
      document.getElementById('password').style.border = '1px solid red';
      setPasswordError('Password is required');
      return;
    }

    try {
        const data = {
            'Email': email,
            'Password': password
        };
        const response = await ApiCall('usp_Tcp_LoginAuth', data);
        const LoginResults = response?.data?.Table[0]['Results'];
        setResults(LoginResults);
        setResultsMessage(response?.data?.Table[0]['ResultsMessage']);
        const UserProfile = response?.data?.Table1;

        if (LoginResults === 'Successful') {
            UserProfile[0]['isLoggedIn'] = true;
          UserProfile[0]['keepSignedIn'] = keepSignedIn;
            await dispatch(ProfileLogin(UserProfile));

          // Check for MFA requirements and navigate to MFA page if necessary
          if (
                LoginResults === 'Successful' && 
                (UserProfile[0]?.MfaAuthenticator === true || 
                UserProfile[0]?.MfaSms === true || 
                UserProfile[0]?.MfaEmail === true) && 
                UserProfile[0]?.MfaLoggedIn === false
            ) {
                navigate('/customer/login/Mfa');
                return; // prevent further execution if redirected to MFA
            }

            var dataA = {
                'Email': email
            }
            const responseA = await ApiCall('tq_ReturnCustomersForEmail', dataA, 'Tcp')
            const AccountsData = responseA?.data?.Table;
            await dispatch(Accounts(AccountsData));
            if (AccountsData.length > 1) {
                navigate('/customer/accounts');
            } else {
                navigate('/home');
            }
        }
    } catch (error) {
        setEmailError(error.message || 'An error occurred during login.');
    } finally {
        setLoginLoading(false);
        setdisableFields(false);
    }
};


  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailError("");
      setPasswordError("");
    }, 30000);
    return () => clearTimeout(timer);
  }, [emailError, passwordError]);

  return (
    <ErrorBoundary componentName="CustomerLogin" fallback={<ErrorMessage />}>
      <div className="forms-container">
        <h2>Account Login</h2>
        <form onSubmit={handleLogin}>
          <div className="forms-box">
            <div className="input-group">
              <label htmlFor="email">E-mail :</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setUserEmail(e.target.value)}
                disabled={disableFields}
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </div>
            <div className="input-group">
              <label htmlFor="password">Password :</label>
              <input
                type="password"
                id="password"
                autoComplete="current-password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={disableFields}
              />
              {passwordError && <div className="error-message">{passwordError}</div>}
              <div className="input-controls">
                <GreyCheckbox
                  label="Keep Me Signed In"
                  checked={keepSignedIn}
                  onChange={(e) => setKeepSignedIn(e)}
                />
                <span className="forgot-password" onClick={() => navigate('/customer/passwordreset')}>Forgot Password?</span>
              </div>
            </div>
            <button type="submit" className="universal-btn LoginShell-buttons">
              {!loginLoading ? <span>LOG IN</span> : <><span style={{ paddingRight: '10px' }}>Logging In</span><i className="fa fa-spinner fa-spin " /></>}
            </button>
            {ResultsMessage && <div className="error-message">{ResultsMessage}</div>}
            {/*TODO: uncomment when signup page is approved for launch*/}
            {/* <p className="new-user-sign-up-link" onClick={() => navigate('/customer/signup')}>New Users Sign Up Here</p> */}
          </div>
        </form>
        {EmployeeLoggedIn && (
          <div>
            <p className="btn btn-primary" onClick={() => navigate('/employee/users')}>View All Users</p>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default LoginCustomer;
