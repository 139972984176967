import { createSlice } from "@reduxjs/toolkit";

const billingInputsSlice = createSlice({
  
  name: "billingInputs",
  initialState: [
    { id: 1, billingOrganizationName: "TestName1", billingContactName: "TestName1", billingCountry: "TestName1", billingAddressA: "TestName1", billingAddressB: "TestName1", billingCity: "TestName1", billingState: "TestName1", billingPostalCode: "TestName1", billingPhone: "TestName1", billingEmail: "TestName1", billingWebsite: "TestName1" },
    // { id: 2, billingOrganizationName: "TestName2" }
  ],
  reducers: {
    addBillingInputs: (state, action) => {
      const newBillingInputs = {
        // id: Date.now(),
        billingOrganizationName: action.payload.billingOrganizationName,
        billingContactName: action.payload.billingContactName,
        billingCountry: action.payload.billingCountry,
        billingAddressA: action.payload.billingAddressA,
        billingAddressB: action.payload.billingAddressB,
        billingCity: action.payload.billingCity,
        billingState: action.payload.billingState,
        billingPostalCode: action.payload.billingPostalCode,
        billingPhone: action.payload.billingPhone,
        billingEmail: action.payload.billingEmail,
        billingWebsite: action.payload.billingWebsite,
      };
      // state.push(newBillingInputs);
      return newBillingInputs;
  },
}
});

export const { 
  addBillingInputs 
} = billingInputsSlice.actions;
export default billingInputsSlice.reducer;