import axios from 'axios';
import { ApiCallUrl } from '../config/apiconfig';
import {
  cryptAPIKey, encryptUsingAES256Base64, decryptUsingAES256
} from './Security/crypt';

export const ApiCall = async (StoredProcedure, data, database = null, timeout = null, tries = null) => {
  try {
    data['StoredProcedure'] = StoredProcedure;

    //database values (tcp, max, remotedb, itsm)
    if (database === null) {
      data['Database'] = 'sqlCon'
    }
    else {
      data["Database"] = database;
    }

    if (timeout !== null) {
      data['Timeout'] = timeout;
    }

    if (tries !== null) {
      data['Tries'] = tries;
    }

    const requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    };

    const encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(ApiCallUrl, encryptedData, { headers: requestHeaders, timeout: 10000 });

    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: `An error occurred: ${error.message}` };
  }
};