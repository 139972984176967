import React from "react";
import { useLocation } from 'react-router-dom';
import PasswordReset from "./PasswordReset";
import DynamicTabs from "./DynamicTabs";
import MfaPreferences from "./MfaPreferences";

function CustomerSecurity({ isDesktop }) {
  const location = useLocation();
  const activeUrl = location.pathname.split('/')[2].toLowerCase();

  return (
    <>
      <div className="tabsAndPages-wrapper">
        <DynamicTabs />
      </div>
      <div>
        {activeUrl === 'security' &&
          <PasswordReset pageName={ activeUrl } />
        }
      </div>
      <div>
        {activeUrl === 'mfapreferences' &&
          <MfaPreferences isDesktop={isDesktop} />
        }
      </div>
    </>
  );
}

export default CustomerSecurity;
