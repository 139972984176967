import React from "react";
import DynamicTabs from "./DynamicTabs";

function ManageLogosC() {
  return (
    <>
      <div className="tabsAndPages-wrapper">
        <DynamicTabs />
      </div>
      <div>Pending Page design</div>
    </>
  )
}

export default ManageLogosC