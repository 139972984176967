import { useEffect, useState } from 'react';
import { ApiCall } from '../components/ApiCall';

const useEmailAlertsSettings = (alertID) => {
  const [emailSettings, setEmailSettings] = useState({
    emailTo: [],
    emailCc: [],
    salesRepID: "TCP",
    firstEmailTo: "",
    firstEmailCC: "",
    firstUserName: "",
    error: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        'AlertID': alertID,
      };
      try {
        const response = await ApiCall('tq_SettingsAlerts', data, 'tcpsql');
        const users = response?.data?.Table || [];
        const usersIfNotFound = response?.data?.Table1 || [];

        let emailTo = [];
        let emailCc = [];
        let firstEmailTo = "";
        let firstEmailCC = "";
        let firstUserName = "";
        let salesRepID = "TCP";
        let error = false;

        if (users.length > 0) {
          users.forEach((user) => {
            if (user.EmailTo) {
              emailTo.push(user.Email);
              if (firstEmailTo === "") {
                firstEmailTo = user.Email;
              }
            }
            if (user.EmailCC) {
              emailCc.push(user.Email);
              if (firstEmailCC === "") {
                firstEmailCC = user.Email;
              }
            }
          });
          
          salesRepID = users[0]?.SalesRepID || salesRepID;
          firstUserName = users[0]?.UserName || firstUserName;
          
        } else {
          error = true;
          emailTo = usersIfNotFound.map(user => user.Email);
          if (usersIfNotFound.length > 0) {
            firstEmailTo = usersIfNotFound[0].Email;
            salesRepID = usersIfNotFound[0]?.SalesRepID || salesRepID;
            firstUserName = usersIfNotFound[0]?.UserName || firstUserName;
          }
        }
        if (firstEmailTo === "" && usersIfNotFound.length === 0) {
          firstEmailTo = "tim@triplecrownproducts.com";
        }

        setEmailSettings({
          emailTo,
          emailCc,
          salesRepID,
          firstEmailTo,
          firstEmailCC,
          firstUserName,
          error,
        });

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [alertID]);

  return emailSettings;
};

export default useEmailAlertsSettings;
