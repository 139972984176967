import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import { ApiCall } from "../ApiCall";

function EmailConfirmation() {
  const [Results, setResults] = useState('');
  const [Message, setMessage] = useState('');
  const { resetrowguid } = useParams();

  useMemo(() => {
    (async () => {
      var data = {
        ResetRowGuid: resetrowguid
      };
      const response = await ApiCall('usp_Tcp_PasswordConfirmation', data);
      setResults(response?.data?.Table[0]['Results']);
      setMessage(response?.data?.Table[0]['ResultsMessage']);
    })();
  }, [resetrowguid]);

  return (
    <ErrorBoundary componentName='EmailConfirmation'>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="text-center">
          <h1>Password Confirmation</h1>
          {Results === 'Successful' && <p>Your email has been confirmed.</p>}
          {Results === 'Failed' && <p>{Message}</p>}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default EmailConfirmation;