import React, { useState } from 'react';

const GreyCheckbox = ({ label, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxToggle = () => {
    setIsChecked(!isChecked);
    if (onChange) {
      onChange(!isChecked);
    }
  };

  return (
    <div className="grey-checkbox-n-label" onClick={handleCheckboxToggle}>
      <span className="grey-checkbox">{isChecked ? '✓' : ''}</span>
      <span className="grey-checkbox-label">{label}</span>
    </div>
  );
};

export default GreyCheckbox;
