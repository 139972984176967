import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CustomerAuth = () => {
  const authTokenCustomer = useSelector((state) => state?.customer?.isLoggedIn);
  const location = useLocation()

  return (
    authTokenCustomer
      ?
      <Outlet />
      :
      <Navigate to='/customer/login' state={{ from: location }} replace />
  )
}
export default CustomerAuth
