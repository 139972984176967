import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FormattedTitleCase from '../DataManipulation/FormatTitleCase';

import "../../styles/DynamicTabs.scss";

function DynamicTabs({ tabOverride }) {
  const navigate = useNavigate();
  const location = useLocation();

  const tabsConfig = useMemo(() => ([
    // TODO: add the commented out tabs when ready it's not a good idea to simeply delete them because they are already wired up in the project.
    ['profile', //'tax exemption' 
      ],
    ['orders', 'unpaid invoices', 'invoices', //'saved carts'
    ],
    ['email your sales associate'],  
    ['manage logos'],
    ['security', //'multi-factor authentication'

    ],
  ]), []);

  const activeTab = useMemo(() => {
    const pathSegments = location.pathname.split('/');
    const tabSegment = pathSegments[2];
    return tabOverride !== undefined ? tabOverride : tabSegment || null;
  }, [location.pathname, tabOverride]);

  const pageName = useMemo(() => {
    const foundPage = Object.entries(tabsConfig).find(([_, tabs]) =>
      tabs.some(tab => tab.replace(/\s/g, '') === activeTab)
    );
    return foundPage ? foundPage[0] : null;
  }, [tabsConfig, activeTab]);

  const tabs = useMemo(() => tabsConfig[pageName] || [], [pageName, tabsConfig]);

  useEffect(() => {
    if (tabs.length > 0 && !activeTab) {
      navigate(`/customer/${tabs[0].replace(/\s/g, '')}`);
    }
  }, [tabs, activeTab, navigate]);

  const handleTabClick = (tab) => {
    const tabWithoutSpace = tab.replace(/\s/g, '');
    if (tabWithoutSpace === 'emailyoursalesassociate') {
      navigate('/customer/support');
    }
    if (tabWithoutSpace === 'multi-factorauthentication') {
      navigate('/customer/mfapreferences');
    }
    else {
      navigate(`/customer/${tabWithoutSpace}`);
    }
  };

  const getTabLabel = (tab) => {
    if (tab === 'security') {
      return 'Reset Password';
    }
    return tab;
  };

  return (
    <div className="tabs-wrapper">
      <div className="tab-container">
        {tabs.map((tab) => (
          <span
            key={tab}
            className={`tab ${activeTab === tab.replace(/\s/g, '') ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            <FormattedTitleCase value={getTabLabel(tab)} />
          </span>
        ))}
        <span className="spacer-tab"></span>
      </div>
    </div>
  );
}

export default DynamicTabs;
