import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import NewBanner from "./NewBanner";
import FiveDayShip from "./FiveDayShip";
import Loading from "../loading";

function ProductItem(props) {
  const { data } = props;
  const ImageUrl = useSelector((state) => state?.siteWideSettings?.imageServerUrl);
  const [imageLoaded, setImageLoaded] = useState(false);


  const fullImageUrl = `${ImageUrl}Cropped?Path=Product/${data.ProductID}.jpg&Width=350&Height=435`;

  useEffect(() => {
    const image = new Image();
    image.src = fullImageUrl;

    image.onload = () => {
      setImageLoaded(true);
    };

    // Cleanup if the component unmounts during loading
    return () => {
      image.onload = null;
    };
  }, [fullImageUrl]);

  function storeScrollPosition() {
    const scrollPosition = Math.floor(window.scrollY);
    const url = new URL(window.location.href);
    url.searchParams.set('scroll', scrollPosition);
    window.history.replaceState({ path: url.href }, "", url.href);
  }
  

      // Define the style for the loading spinner
      const loadingStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '435px',  // Matches height to our image height to ensure the spinner centers vertically
        width: '100%'    // force full width of the image container
      };

  return (
    <React.Fragment>
      <Link
        className="products-item-wrapper"
        to={`https://triplecrownproducts.com/${data.PageName.toLowerCase()}-custom-logo-embroidered-printed.htm`}
        onClick={storeScrollPosition}
      >
        {/* TODO: switch link to back to `/product/${data.PageName.toLowerCase()}-custom-logo-embroidered-printed.htm` */}
        <div className="products-item h-100 text-left" id={`product${data.ProductNumber}`}>
          <div className="img-hover-text">
            <div className="img-product-wrapper position-relative">
              {!imageLoaded && <div style={loadingStyle}><Loading /></div>}
              {imageLoaded && (
                <>
                  <img
                    className="img img-fluid mx-auto product-img"
                    src={fullImageUrl}
                    alt={data.ProductName}
                  />
                  {data.FiveDayShip ? <FiveDayShip /> : data.IsNew ? <NewBanner /> : null}
                  <div className="product-item-description-hover">
                    VIEW FULL DETAILS
                  </div>
                </>
              )}
            </div>
          </div>
          {imageLoaded && (
            <div className="product-item-description d-flex flex-column" style={{ minHeight: "10vw" }}>
              <div className="title">
                {data.BrandName} #{data.ProductNumber}
              </div>
              <div className="description">{data.ProductName}</div>
              {data.ItemTemplateID === 57 || data.ItemTemplateID === 63 ? (
                <div className="colors-pricing mt-1">
                  {data.Sizes} in {data.TotalColors} Colors.
                </div>
              ) : null}
              <div className="colors-pricing mt-1">
                As low as ${data.LowestPrice.toFixed(2)}
              </div>
            </div>
          )}
        </div>
      </Link>
    </React.Fragment>
  );
}

export default ProductItem;
