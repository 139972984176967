import React, { useState, useEffect } from 'react';

const OrangeCheckBox = ({ label, onChange, isChecked }) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleCheckboxToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div className="orange-checkbox-n-label" onClick={handleCheckboxToggle} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <span
        className="orange-checkbox"
        style={{
          display: 'inline-block',
          width: '25px',
          height: '25px',
          textAlign: 'center',
          lineHeight: '20px',
          marginRight: '8px',
          borderRadius: '3px',
          border: checked ? `2px solid #FA873d`: '1px solid #d3d3d3',
          color: checked ? '#FA873d' : 'transparent',
          fontSize: '22px',
          fontWeight: 'bolder',
        }}>
        {checked ? '✓' : ''}
      </span>
      <span className={`orange-checkbox-label ${checked ? 'active' : 'inactive'}`}>{label}</span>
    </div>
  );
};

export default OrangeCheckBox;
