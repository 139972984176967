import axios from 'axios';

const axiosInstance = axios.create({
  timeout: 30000,
});

axiosInstance.defaults.retry = 15; // Set the number of retries you want to attempt
axiosInstance.defaults.retryDelay = 1000; // Set the delay (in milliseconds) between retries

axiosInstance.interceptors.response.use(
  undefined,
  (err) => {
    const { config, response } = err;

    if (!config || !config.retry) {
      return Promise.reject(err);
    }

    config.retryCount = config.retryCount || 0;

    if (config.retryCount >= config.retry) {
      return Promise.reject(err);
    }

    config.retryCount += 1;

    return new Promise((resolve) => {
      setTimeout(() => resolve(axiosInstance(config)), config.retryDelay);
    });
  },
);

export default axiosInstance;
