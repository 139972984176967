import DynamicTabs from "./DynamicTabs";

function TaxExemption() {
  return (
    <>
      <DynamicTabs />
      
    </>
  );
}

export default TaxExemption;