import TcpMaxDatabase from "../TcpMaxDatabase";
import {
  encryptUsingAES256Base64, decryptUsingAES256Base64, cryptAPIKey
} from "./crypt";
import {
  SendSmsCodeUrl, VerifySmsCodeUrl, SendEmailCodeUrl, MfaTotpUrl
} from "../../config/apiconfig";
import axios from "axios";

export const postSendSmsCode = (PhoneNumber) => async () => {
  let results = 'Failed';
  let resultsMessage = '';

  try {
    var data = {
      'PhoneNumber': PhoneNumber
    }

    var requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    }

    var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(SendSmsCodeUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });
    results = response?.data?.results || 'Failed';
  } catch (error) {
    resultsMessage = 'An error occurred sending sms code. Error: ' + error;
  }
  finally {
    return { results, resultsMessage };
  }
}

export const postVerifySmsCode = (PhoneNumber, SmsCode) => async () => {
  let results = 'Failed';
  let resultsMessage = '';

  try {
    var data = {
      'PhoneNumber': PhoneNumber,
      'SmsCode': SmsCode
    }

    var requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    }

    var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(VerifySmsCodeUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });
    results = response.status;
  } catch (error) {
    resultsMessage = 'An error occurred sending sms code. Error: ' + error;
  }
  finally {
    return { results, resultsMessage };
  }
}

export const postSendEmailCode = (Email) => async () => {
  let results = 'Failed';
  let resultsMessage = '';

  try {
    var data = {
      'Email': Email
    }

    TcpMaxDatabase(data);

    var requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    }

    var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(SendEmailCodeUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });
    results = response.data.results;
    resultsMessage = response.data.results_message;
  } catch (error) {
    resultsMessage = 'An error occurred sending sms code. Error: ' + error;
  }
  finally {
    return { results, resultsMessage };
  }
}

export const postMfaTotp = (RequestType, Email, TotpCode) => async () => {
  let results = 'Failed';
  let resultsMessage = '';
  let totpSecret = '';

  try {
    var data = {
      'RequestType': RequestType,
      'Email': Email,
      'TotpCode': TotpCode
    }

    TcpMaxDatabase(data);

    var requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    }

    var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(MfaTotpUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });
    results = response?.data?.results;
    resultsMessage = response?.data?.results_message;
    totpSecret = response?.data?.totp_secret;
  } catch (error) {
    resultsMessage = 'An error occurred sending sms code. Error: ' + error;
  }

  return { results, resultsMessage, totpSecret };
}
