import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

function PageMeta() {
  const pageMeta = useSelector((state) => state?.siteWideSettings?.pageMeta);

  return (
    <Helmet>
      <title>{pageMeta?.customTitle || pageMeta?.title}</title>
      <link rel="canonical" href={pageMeta?.canonical} />
      <meta name="description" content={pageMeta?.customDescription || pageMeta?.description} />
      <meta name="keywords" content={pageMeta?.keywords} />
    </Helmet>
  );
}

export default PageMeta;
