import React, { useState } from "react";
import { FaSpinner } from 'react-icons/fa';
import ErrorBoundary from "../ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import PasswordStrengthMeter from "../PasswordStrengthMeter";
import useEmailFormatValidator from "../../Hooks/useEmailValidation";
import { ApiCall } from "../ApiCall";
import SendEmail from "../SendEmail";
import ReactDOMServer from 'react-dom/server';
import SignUpTemplate from '../EmailTemplates/SignUp';
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";
import { useDispatch } from "react-redux";

function SignupCustomer() {
  const dispatch = useDispatch();
  const getEmailValidationState = useEmailFormatValidator();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [results, setResults] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);
  const emailValidationState = getEmailValidationState(email);
  dispatch(updatePageMeta("Customer Signup"));

  const handleSignup = async () => {
    // Reset error messages
    setNameError('');
    setEmailError('');
    setPasswordError('');
    setPasswordMatchError('');
    setResultsMessage('');

    // Reset border colors
    document.getElementById('name').style.border = '1px solid #ced4da';
    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('password').style.border = '1px solid #ced4da';
    document.getElementById('confirmPassword').style.border = '1px solid #ced4da';

    let isValid = false;

    if (firstName.trim().length <= 1 || lastName.trim().length <= 1) {
      // Validate name
      document.getElementById('name').style.border = '1px solid red';
      setNameError('Name must be more than one character.');
    } else if (firstName.trim().length > 25 || lastName.trim().length > 25) {
      // Validate name
      document.getElementById('name').style.border = '1px solid red';
      setNameError('Name must be less than 50 characters.');
    } else if (!emailValidationState.isValid) {
      // Validate email
      document.getElementById('email').style.border = '1px solid red';
      setEmailError(emailValidationState.message);
    } else if (newPassword.trim() === '') {
      // Validate passwords
      document.getElementById('password').style.border = '1px solid red';
      setPasswordError('Password is required.');
    } else if (newPassword !== confirmNewPassword) {
      // Validate passwords
      document.getElementById('confirmPassword').style.border = '1px solid red';
      setPasswordMatchError('Passwords do not match.');
    } else {
      isValid = true;
    }

    // Proceed with form submission only if all validations pass
    if (isValid) {
      setLoading(true);
      setResetRequested(true);
      try {
        var data = {
          'FirstName': firstName,
          'LastName': lastName,
          'Email': email,
          'NewPassword': newPassword
        }

        const response = await ApiCall('usp_Tcp_AccountSignUp', data)
        setResults(response?.data?.Table[0]["Results"]);
        setResultsMessage(response?.data?.Table[0]["ResultsMessage"]);
        if (response?.data?.Table[0]['Results'] === 'Successful') {
          const EmailBody = ReactDOMServer.renderToString(
            <SignUpTemplate resetPasswordURL={window.location.origin + process.env.PUBLIC_URL + '/customer/emailconfirmation/' + response?.data?.Table[0]['ResetRowGuid']} />
          );
          await SendEmail(email, '', 'TCP Email Confirmation', EmailBody, 'noreply@triplecrownproducts.com');
        }
      } catch (error) {
        setResultsMessage('An error occurred during signup');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ErrorBoundary componentName="CustomerSignup" fallback={<ErrorMessage />}>
      <div className="forms-container positioning">
        <h2>SIGN UP</h2>
        <div className="forms-box">
          {results === 'Successful' ? (
            <div className={`process-results ${results === 'Successful' ? 'process-success' : (resultsMessage ? 'process-error' : '')}`}>
              {results} {resultsMessage && `- ${resultsMessage}`}
            </div>
          ) : (
            <>
              <div className="input-group">
                <label htmlFor="name">NAME:</label>
                <div className="input-group-side-by-side">
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={resetRequested}
                  />
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={resetRequested}
                  />
                </div>
                {nameError && <div className="error-message">{nameError}</div>}
              </div>
              <div className="input-group">
                <label htmlFor="email">EMAIL:</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} autoComplete="username"
                  disabled={resetRequested}
                />
                {emailError && <div className="error-message">{emailError}</div>}
              </div>
              <div className="input-group">
                <label htmlFor="password">PASSWORD:</label>
                <input
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)} autoComplete="new-password"
                  disabled={resetRequested}
                />
                {passwordError && <div className="error-message">{passwordError}</div>}
                <PasswordStrengthMeter password={newPassword} />
              </div>
              <div className="input-group">
                <label htmlFor="confirmPassword">CONFIRM PASSWORD:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm your password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)} autoComplete="new-password"
                  disabled={resetRequested}
                />
                {passwordMatchError && <div className="error-message">{passwordMatchError}</div>}
              </div>
              <button
                type="button"
                className="universal-btn LoginShell-buttons"
                onClick={handleSignup}
                disabled={loading}>
                {loading ? (
                  <>
                    SIGNING UP... PLEASE WAIT
                    <FaSpinner className="fa-spin" style={{ marginLeft: "5px", animation: "spin 1s linear infinite" }} />
                  </>
                ) : (
                  "CREATE ACCOUNT"
                )}
              </button>
              <div className="login-group mt-5 d-flex justify-content-center align-items-center">
                {results === 'Failed' && <div className="error-message">{resultsMessage}</div>}
              </div>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default SignupCustomer;
