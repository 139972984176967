import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/CookieConsent.scss';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    const isContactPage = location.pathname === '/contact-form';
    if (!consent && !isContactPage) {
      setShowConsent(true);
    }
  }, [location]);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true'); // TODO encrypt this once we no longer have TcpMobile
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div className="cookie-consent">
        <p className="mb-0">We use cookies to enhance your browsing experience and provide 
          <br className="d-none d-md-block" />
          personalized content. By continuing to use our site, you consent to our cookie policy.
          <br className="d-none d-md-block" /> 
          Learn more about how we use cookies in our <Link to="/customer-assistance/privacy-policy">Privacy Policy</Link>.
        </p>
        <button className= "agree-btn" onClick={acceptCookies}>AGREE & CONTINUE</button>
    </div>
  );
};

export default CookieConsent;