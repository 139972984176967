import { createSlice } from "@reduxjs/toolkit";
import {
  decryptObj,
  encryptUsingAES256,
  cryptAPIKey,
} from "../components/Security/crypt";
import { cartDetailsUrl } from "../config/apiconfig";

const initialState = {
  loading: false,
  details: null,
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    loadCartDetailsPending: (state) => {
      state.loading = true;
    },
    loadCartDetailsSuccess: (state, action) => {
      state.loading = false;
      state.details = action.payload;
      state.error = null;
    },
    loadCartDetailsError: (state, action) => {
      state.loading = false;
      state.details = null;
      state.error = action.payload;
    },
    updateCartDetails: (state, action) => {
      state.details = action.payload;
    },
    updatePromoCodeDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});

export const {
  loadCartDetailsPending,
  loadCartDetailsSuccess,
  loadCartDetailsError,
  updateCartDetails,
  updatePromoCodeDetails,
} = cartSlice.actions;

export const loadCartDetails = (params) => {
  return (dispatch) => {
    dispatch(loadCartDetailsPending());
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-ApiKey": cryptAPIKey(process.env.REACT_APP_SECRET_KEY),
      },
      body: JSON.stringify(encryptUsingAES256(JSON.stringify(params))),
      redirect: "follow",
    };
    fetch(cartDetailsUrl, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text();
        }
      })
      .then((result) => {
        dispatch(loadCartDetailsSuccess(decryptObj(result)));
      })
      .catch((error) => dispatch(loadCartDetailsError(error)));
  };
};

export default cartSlice.reducer;
