import React, { useState } from 'react';
import { ApiCall } from "./../ApiCall.js";
import validateEmail from "./../DataValidation/EmailValidation.js";
import useEmailAlertsSettings from "../../Hooks/useEmailAlertsSettings";
import SendEmail from './../SendEmail';
import { Link } from "react-router-dom";
import HtmlImportParser from '../DataManipulation/HtmlImportParser';

const MailSubscription = ({ content }) => {
  const [viewState, setViewState] = useState('form');
  const [subscribeOption, setSubscribeOption] = useState('subscribe');
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    organizationName: '',
    contactName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    phone: '',
    email: '',
    comments: ''
  });
  const [errorVisible, setErrorVisible] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const followUp = useEmailAlertsSettings(6);
  const newContactRep = useEmailAlertsSettings(7);

  const validate = () => {
    let errors = {};

    if (!formData.contactName || formData.contactName.trim() === "") {
      errors.contactName = "Contact name is required.";
    }

    if (!formData.address1 || formData.address1.trim() === "") {
      errors.address1 = "Address 1 is required.";
    }

    if (!formData.city || formData.city.trim() === "") {
      errors.city = "City is required.";
    }

    if (!formData.state || formData.state === "") {
      errors.state = "Please select a state.";
    }

    if (!formData.postalCode || formData.postalCode.trim() === "") {
      errors.postalCode = "Postal code is required.";
    }

    if (!formData.country || formData.country === "") {
      errors.country = "Please select a country.";
    }

    const emailValidation = validateEmail(formData?.email);
    if (!emailValidation?.isValid) {
      if (emailValidation?.error === 'Email is required.') {
      }
      else {
        errors.email = emailValidation?.error;
      }
    }

    if (containsWebsiteUrl(formData.comments)) {
      errors.comments = "Sorry, URLs are not allowed in the comments.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const containsWebsiteUrl = (comment) => {
    const urlPattern = /(www\.|https?:\/\/)\S+/;
    return urlPattern.test(comment);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const formatMatchTable = (matches, websiteContactID) => {
    let matchTableHtml = "<table><tr><td>CustID</td><td>ContactID</td><td>CustName</td><td>Invoices</td><td>Orders</td><td>Quotes</td><td>Other</td><td>RepName</td><td>Match</td><td></td></tr>";
    matches.forEach(match => {
      if (!match.BestMatch) {
        matchTableHtml += `<tr><td>${match.CustID}</td><td>${match.ContactID}</td><td>${match.CustName}</td><td>${match.Invoices}</td><td>${match.Orders}</td><td>${match.Quotes}</td><td>${match.Other}</td><td>${match.RepName}</td></td><td></td><td></td></tr>`;
      } else {
        let links = "<a href='https://remote.max.maxhat.com/TcpSalesCenterV2/MailSubscription?WebsiteContactID=" + websiteContactID + "'>BAD MATCH</a>";

        if (match.ContactID > 0) {
          links += "<br /><a href='https://remote.max.maxhat.com/TcpSalesCenterV2/MailSubscription?ContactID=" + match.ContactID + "'>Search Customers</a>";
        }
        matchTableHtml += `<tr><td><b>${match.CustID}</b></td><td><b>${match.ContactID}</b></td><td><b>${match.CustName}</b></td><td><b>${match.Invoices}</b></td><td><b>${match.Orders}</b></td><td><b>${match.Quotes}</b></td><td><b>${match.Other}</b></td><td><b>${match.RepName}</b></td><td><b>X</b></td><td><b>${links}</b></td></tr>`;
      }
    });
    matchTableHtml += "</table>";
    return matchTableHtml;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendLoading(true);
    setErrorVisible(false);

    const isValid = validate();
    if (!isValid) {
      setErrorVisible(true);
      setSendLoading(false);
      return;
    }

    let messageHtml = "<tr><td><b>Organization Name</b></td><td>" + formData?.organizationName?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Contact Name</b></td><td>" + formData?.contactName?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Address 1</b></td><td>" + formData?.address1?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Address 2</b></td><td>" + formData?.address2?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>City</b></td><td>" + formData?.city?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>State</b></td><td>" + formData?.state?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Postal Code</b></td><td>" + formData?.postalCode?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Country</b></td><td>" + formData?.country?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Phone</b></td><td>" + formData?.phone?.trim() + "</td></tr>";
    messageHtml += "<tr><td><b>Email</b></td><td>" + formData?.email?.trim() + "</td></tr>";
    messageHtml += "<tr><td>&nbsp;</td><td>&nbsp;</td></tr>";
    messageHtml += "<tr><td><b>Questions/Comments</b></td><td>" + formData?.comments?.trim() + "</td></tr>";
    messageHtml = "<html><table>" + messageHtml + "</table></html>";

    let subject = subscribeOption === 'subscribe' ? "TripleCrownProducts.com - Catalog Request for " : "TripleCrownProducts.com - Unsubscribe From Catalog for ";
    subject += formData?.organizationName?.trim() ? formData?.organizationName?.trim() : formData?.contactName?.trim();


    var mailSubscriptionData = {
      "Subscribe": subscribeOption === 'subscribe' ? 1 : 0,
      "Unsubscribe": subscribeOption === 'unsubscribe' ? 1 : 0,
      "CustName": formData?.organizationName?.trim(),
      "Contact": formData?.contactName?.trim(),
      "Address1": formData?.address1?.trim(),
      "Address2": formData?.address2?.trim(),
      "City": formData?.city?.trim(),
      "State": formData?.state?.trim(),
      "Country": formData?.country?.trim(),
      "Zip": formData?.postalCode?.trim(),
      "Phone": formData?.phone?.trim(),
      "Email": formData?.email?.trim(),
    };


    const mailResponse = await ApiCall('tq_Contact_MailSubscription', mailSubscriptionData, 'TCP');
    const contactID = mailResponse?.data?.Table?.[0]?.ContactID;

    if (mailResponse?.error) {
      setViewState('error');
      return;
    }

    var contactUsData = {
      "CustName": formData?.organizationName?.trim(),
      "Contact": formData?.contactName?.trim(),
      "Address1": formData?.address1?.trim(),
      "Address2": formData?.address2?.trim(),
      "City": formData?.city?.trim(),
      "State": formData?.state?.trim(),
      "Country": formData?.country?.trim(),
      "Zip": formData?.postalCode?.trim(),
      "Phone": formData?.phone?.trim(),
      "Email": formData?.email?.trim(),
      "Website": "",
      "EmailSubject": subject,
      "EmailMessage": messageHtml,
      "DefaultUserName": newContactRep?.firstUserName || "",
      "DefaultSalesRepID": newContactRep?.salesRepID || "",
      "Source": "Mail Subscription",
      "OptionalCustID": "",
      "OptionalContactID": contactID || "",
      "FollowUpSalesRepID": followUp?.salesRepID,
    };

    const contactResponse = await ApiCall('tq_Contact_Us_V2', contactUsData, 'TCP');

    if (contactResponse?.error) {
      setViewState('error');
      return;
    }

    let matches = contactResponse?.data?.Table2;
    let matchTable = "";
    let bestMatch = contactResponse?.data?.Table3?.[0];
    let followUpContact = contactResponse?.data?.Table4?.[0];
    let websiteContactID = contactResponse?.data?.Table5?.[0]?.WebsiteContactID;
    let duplicate = contactResponse?.data?.Table6?.[0]?.Duplicate;
    let matchedCustID = bestMatch?.CustID;
    if (matchedCustID.toLowerCase().includes('c')) {
      matchedCustID = "";
    }
    let repEmail = bestMatch?.RepEmail;
    let followUpRepEmail = followUpContact?.FollowUpRepEmail;

    if (matches.length > 0) {
      matchTable = formatMatchTable(matches, websiteContactID);
    }

    if (!duplicate) {
      if (formData?.email != "") {
        SendEmail(formData?.email, "", "COPY: " + subject, messageHtml, "auto-mate@triplecrownproducts.com");
      }

      if (!contactResponse?.error) {
        const closingTagsIndex = messageHtml.lastIndexOf('</table></html>');
        const temp = messageHtml.slice(0, closingTagsIndex) +
          "<tr><td><b>Matches</b></td><td>" + matchTable + "</td></tr>" +
          messageHtml.slice(closingTagsIndex);
        messageHtml = temp;

        if (followUpRepEmail == "" && repEmail == "" && followUp?.firstEmailCC == "") {
          SendEmail("DEVTeam@triplecrownproducts.com", "", "ERROR: Mail Subscription Contact: " + subject,
            "Mail Subscription Contact provided no email address to send this contact to: " + messageHtml, "info@triplecrownproducts.com");
        } else if (repEmail == "") {
          SendEmail(followUpRepEmail, "", subject, messageHtml, "info@triplecrownproducts.com");
        } else {
          SendEmail(followUpRepEmail, repEmail, subject, messageHtml, "info@triplecrownproducts.com");
        }

        repEmail = repEmail == "" ? "No one" : repEmail;

        if (followUp?.firstEmailCC != "") {
          SendEmail(followUp?.firstEmailCC, "", subject, "<b>Copy of email that was sent to " + followUpRepEmail + " and cc to " + repEmail + "</b><br><br>" + messageHtml, "info@triplecrownproducts.com");
        }
      } else {
        SendEmail(followUpRepEmail, "DEVTeam@triplecrownproducts.com", subject, messageHtml + "<b>ERROR ADDING TO DATABASE: " + contactResponse?.error + "</b>", "info@triplecrownproducts.com");
      }


    }

    if (!mailResponse?.error) {
      setViewState(subscribeOption === 'subscribe' ? 'subscribed' : 'unsubscribed');
    }

  };

  return (
    <>
      <HtmlImportParser content={content?.Html || ''} />
      <div className="container">
        <div className="row mb-3">
          <div className="col-12">
            {viewState === 'error' && (
              <p className="alert alert-danger">
                An error occurred while adding you to the mailing list. <br />
                Please try again later or <Link to="/customer-assistance/contact-us">visit our contact page</Link> so we can assist you.
              </p>
            )}
          </div>
        </div>
        {viewState === 'unsubscribed' && (
          <p>
            Your request to be unsubscribed has been successfully submitted.<br />
            We will do our best to have your address removed from our mailing list for future publications.
          </p>
        )}

        {viewState === 'subscribed' && (
          <p>
            Your catalog subscription request has been successfully submitted!<br />
            You should receive a catalog within a week and then will be included in our mailing list for future publications.
          </p>
        )}

        {viewState === 'form' && (
          <>
            <div className="row justify-content-center MailSubscription-form-div">
              <form onSubmit={handleSubmit} noValidate className="form">
                <div className="col-12">
                  {errorVisible && <div className="alert alert-danger">Please complete all required fields before proceeding.</div>}
                </div>
                <div className="MailSubscription-radio-div">
                  <label className="MailSubscription-radio-label">
                    <input
                      className="MailSubscription-radio"
                      type="radio"
                      value="subscribe"
                      checked={subscribeOption === 'subscribe'}
                      onChange={() => setSubscribeOption('subscribe')}
                    />
                    <span className="MailSubscription-radio-custom"></span>Subscribe
                  </label>
                  <label className="MailSubscription-radio-label">
                    <input
                      className="MailSubscription-radio"
                      type="radio"
                      value="unsubscribe"
                      checked={subscribeOption === 'unsubscribe'}
                      onChange={() => setSubscribeOption('unsubscribe')}
                    />
                    <span className="MailSubscription-radio-custom"></span>
                    Unsubscribe
                  </label>
                </div>
                <div className="form-group row mb-3 mt-3">
                  <label htmlFor="organizationName" className="col-md-3 col-form-label text-md-end MailSubscription-label">Organization:</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="organizationName"
                      id="organizationName"
                      value={formData.organizationName}
                      onChange={handleChange}
                      className={`form-control ${formErrors.organizationName ? 'is-invalid' : ''}`}
                      placeholder="Your organizations name.."
                      maxLength="30"
                    />
                    {formErrors.organizationName && <div className="invalid-feedback">{formErrors.organizationName}</div>}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label htmlFor="contactName" className="col-md-3 col-form-label text-md-end MailSubscription-label">Contact:</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="contactName"
                      id="contactName"
                      value={formData.contactName}
                      onChange={handleChange}
                      className={`form-control ${formErrors.contactName ? 'is-invalid' : ''}`}
                      placeholder="Your name.."
                      maxLength="25"
                    />
                    {formErrors.contactName && <div className="invalid-feedback">{formErrors.contactName}</div>}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label htmlFor="address1" className="col-md-3 col-form-label text-md-end MailSubscription-label">Address 1:</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="address1"
                      id="address1"
                      value={formData.address1}
                      onChange={handleChange}
                      className={`form-control ${formErrors.address1 ? 'is-invalid' : ''}`}
                      placeholder="Your street address, P.O. box, company name, c/o.."
                      maxLength="30"
                    />
                    {formErrors.address1 && <div className="invalid-feedback">{formErrors.address1}</div>}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label htmlFor="address2" className="col-md-3 col-form-label text-md-end MailSubscription-label">Address 2:</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="address2"
                      id="address2"
                      value={formData.address2}
                      onChange={handleChange}
                      className={`form-control ${formErrors.address2 ? 'is-invalid' : ''}`}
                      placeholder="Your apartment, suite, unit, building, floor, etc. (optional).."
                      maxLength="60"
                    />
                    {formErrors.address2 && <div className="invalid-feedback">{formErrors.address2}</div>}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label htmlFor="city" className="col-md-3 col-form-label text-md-end MailSubscription-label">City:</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      value={formData.city}
                      onChange={handleChange}
                      className={`form-control ${formErrors.city ? 'is-invalid' : ''}`}
                      placeholder="Your city.."
                      maxLength="30"
                    />
                    {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                  </div>
                </div>

                <div className="row mb-3 align-items-start justify-content-start">
                  <div className="col-sm-3 col-form-label text-md-end">
                    <label htmlFor="state" className="MailSubscription-label">State:</label>
                  </div>
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-md-6 mb-3 mb-md-0">
                        <select
                          name="state"
                          id="state"
                          value={formData.state}
                          onChange={handleChange}
                          className={`form-control ${formErrors.state ? 'is-invalid' : ''}`}
                        >
                          <option value="">Select a state</option>
                          <option value="AL">Alabama</option><option value="AK">Alaska</option><option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option><option value="CA">California</option><option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option><option value="DE">Delaware</option><option value="FL">Florida</option>
                          <option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option>
                          <option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option>
                          <option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option>
                          <option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option>
                          <option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option>
                          <option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option><option value="WY">Wyoming</option>
                        </select>
                        {formErrors.state && <div className="invalid-feedback">{formErrors.state}</div>}
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <label htmlFor="postalCode" className="col-md-2 col-form-label text-md-end MailSubscription-label">Zip:</label>
                          <div className="col-md-10">
                            <input
                              type="text"
                              name="postalCode"
                              id="postalCode"
                              value={formData.postalCode}
                              onChange={handleChange}
                              className={`form-control ${formErrors.postalCode ? 'is-invalid' : ''}`}
                              placeholder="Your postal code.."
                              maxLength="10"
                            />
                            {formErrors.postalCode && <div className="invalid-feedback">{formErrors.postalCode}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="country" className="col-md-3 col-form-label text-md-end MailSubscription-label">Country:</label>
                  <div className="col-md-8">
                    <select
                      name="country"
                      id="country"
                      value={formData.country}
                      onChange={handleChange}
                      className={`form-control ${formErrors.country ? 'is-invalid' : ''}`}
                    >
                      <option value="">Select a country</option>
                      <option value="USA">USA</option>
                    </select>
                    {formErrors.country && <div className="invalid-feedback">{formErrors.country}</div>}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <label htmlFor="phone" className="col-md-3 col-form-label text-md-end MailSubscription-label">Phone:</label>
                  <div className="col-md-8">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
                      placeholder="Your Phone Number.."
                      maxLength="15"
                    />
                    {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="email" className="col-md-3 col-form-label text-md-end MailSubscription-label">Email:</label>
                  <div className="col-md-8">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                      placeholder="Your Email Address.."
                      maxLength="200"
                    />
                    {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="comments" className="col-md-3 col-form-label text-md-end MailSubscription-label">Questions/Comments:</label>
                  <div className="col-md-8">
                    <textarea
                      name="comments"
                      id="comments"
                      value={formData.comments}
                      onChange={handleChange}
                      className={`form-control ${formErrors.comments ? 'is-invalid' : ''}`}
                      placeholder="Your questions/comments..."
                      maxLength="500"
                    ></textarea>
                    {formErrors.comments && <div className="invalid-feedback">{formErrors.comments}</div>}
                  </div>
                </div>

                {/* TODO: Add ReCAPTCHA component */}
                <div className="mb-4 row justify-content-center">
                  <div className="col-12 d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary MailSubscription-submit-button">
                      {!sendLoading &&
                        <span>Submit</span>
                      }
                      {sendLoading &&
                        <>
                          <span style={{ paddingRight: '10px' }}>Submit</span>
                          <i className="fa fa-spinner fa-spin " />
                        </>
                      }
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default MailSubscription;
