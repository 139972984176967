import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EmployeeInfoAuth = () => {
  const authTokenEmployeeInfo = useSelector((state) => state.siteWideSettings.employeeInfoProfile.isLoggedIn);
  const location = useLocation()

  return (
    authTokenEmployeeInfo
      ?
      <Outlet />
      :
      <Navigate to='/employeeinformation/login' state={{ from: location }} replace />
  )
}
export default EmployeeInfoAuth
