import React, { useState } from 'react';

function Preferences() {
  // States to manage the subscription status
  const [subscribedToCatalog, setSubscribedToCatalog] = useState(false);
  const [subscribedToEmail, setSubscribedToEmail] = useState(false);

  // Handle catalog subscription toggle
  const toggleCatalogSubscription = () => {
    //TODO: Implement the logic to update the subscription status in the backend for Production
    setSubscribedToCatalog(!subscribedToCatalog);
  };

  // Handle email subscription toggle
  const toggleEmailSubscription = () => {
    //TODO: Implement the logic to update the subscription status in the backend for Production
    setSubscribedToEmail(!subscribedToEmail);
  };

  return (
    <>
      <div>Preferences</div>
      <div style={{ marginBottom: '20px' }}>
        <label>
          Subscribe to Catalog:
          <input
            type="checkbox"
            checked={subscribedToCatalog}
            onChange={toggleCatalogSubscription}
            style={{ marginLeft: '20px' }}
          />
          <span style={{ marginLeft: '10px' }}>{subscribedToCatalog ? 'Subscribed' : 'Unsubscribed'}</span>
        </label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label>
          Subscribe to Email:
          <input
            type="checkbox"
            checked={subscribedToEmail}
            onChange={toggleEmailSubscription}
            style={{ marginLeft: '20px' }}
          />
          <span style={{ marginLeft: '10px' }}>{subscribedToEmail ? 'Subscribed' : 'Unsubscribed'}</span>
        </label>
      </div>
    </>
  );
}

export default Preferences;
