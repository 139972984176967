import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiCall } from '../components/ApiCall';
import { getActiveImageServer } from '../Redux/siteWideSettingsSlice';

function Settings() {
  const dispatch = useDispatch();
  const [selectedServer, setSelectedServer] = useState('');
  const [imageServerData, setImageServerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await dispatch(getActiveImageServer());
        setImageServerData(response);
        setSelectedServer(response.imageServer);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }

    fetchData();
  }, [dispatch]);

  const handleServerChange = async (event) => {
    setSelectedServer(event.target.value);

    var data = {
      'Server': event.target.value
    }

    try {
      setLoading(true);
      await ApiCall('usp_TcpWebsiteImageUrl', data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center mb-5">
        <div className="col-md-6">
          <select className="form-select" onChange={handleServerChange} value={selectedServer}>
            <option value="SERV12">Tcp (SERV12)</option>
            <option value="SERV12ET">Max (SERV12ET)</option>
          </select>
        </div>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {imageServerData && (
        <div className="row mt-3">
          <div className="col text-center">
            <h5>Location: Tcp</h5>
            <h5>Server: SERV12</h5>
            <img
              src={imageServerData.TcpUrl + "StatusGood.png"}
              alt="SERV12"
              className="img-fluid"
              style={{ maxWidth: "30vw" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "Images/image-error.png";
              }}
            />
          </div>
          <div className="col text-center">
            <h5>Location: Max</h5>
            <h5>Server: SERV12ET</h5>
            <img
              src={imageServerData.MaxUrl + "StatusGood.png"}
              alt="SERV12ET"
              className="img-fluid"
              style={{ maxWidth: "30vw" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "Images/image-error.png";
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;
