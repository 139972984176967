import React, { useState } from 'react';
import {useSelector}from 'react-redux';
import './../../styles/Jobs.scss';

const Jobs = () => {
  const ImageUrl = useSelector((state) => state?.siteWideSettings?.imageServerUrl)
  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-md-12">
          <img 
            className="img img-fluid"
            src={`${ImageUrl}/files/14517-Header.jpg`} 
            alt="Employees working different jobs" 
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h1>Job Openings</h1>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <p className='text-center' style={{fontSize: '1.5rem'}}>
            We have multiple positions 
            available!<br className='d-none d-md-block'/> We are currently hiring 
            for production and office employees.<br className='d-none d-md-block'/> If 
            interested in learning more about opportunities at Triple Crown 
            Products, <br className='d-none d-md-block'/> please 
            submit your resume and completed application via email.
          </p>
        </div>
      </div>
      <div className="row mb-4 justify-content-center">
        <div className="col-12 col-lg-8 d-flex flex-column flex-md-row justify-content-center align-items-center">
          <a
            href={`${ImageUrl}files/4605-Employment%20Application%202019.pdf`}
            className='btn btn-primary btn-lg my-2 my-md-0 me-md-4'
            style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            width: '100%',
            maxWidth: '300px',
            color: 'white'
            }}>Application Form
          </a>
          <a 
            href={'mailto:jobs@triplecrownproducts.com'}
            className='btn btn-primary btn-lg my-2 my-md-0 ms-md-4'
            style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            width: '100%',
            maxWidth: '300px',
            color: 'white'
            }}>E-Mail Your Application
          </a>
        </div>
      </div>
      {/* TODO current job openings will go here*/}
      {/* <div className="row">
        <div className="col-md-12">
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '2.5rem',
            }}>Current Job Openings
          </h2>
        </div>
      </div> */}
      <div className="row mb-4">
        <div className="col-md-12">
          <h2
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '2.5rem',
            }}>Find Us On These Job Sites
          </h2>
        </div>
      </div>
      <div className="row mb-4 justify-content-center">
        <div className="col-12 d-flex flex-column flex-md-row justify-content-center align-items-center">
          <div className="mb-3 mb-md-0 mx-md-2 job-site-card">
            <a href="https://www.ziprecruiter.com/jobs-search?form=jobs-landing&search=triple+crown+products&location=Mukwonago%2C+WI" target="_blank" rel="noopener noreferrer">
              <img 
                src={`${ImageUrl}files/14519-ZipRecruiter.svg`} 
                alt="ZipRecruiter" 
                className="job-site-logo"
              />
            </a>
          </div>
          <div className="mb-3 mb-md-0 mx-md-2 job-site-card">
            <a href="https://www.indeed.com/jobs?q=triple+crown+products&l=&from=searchOnHP&vjk=dfcad03a3bbcb5e8" target="_blank" rel="noopener noreferrer">
              <img 
                src={`${ImageUrl}files/14520-Indeed.svg`} 
                alt="Indeed" 
                className="job-site-logo"
              />
            </a>
          </div>
          <div className="mb-3 mb-md-0 mx-md-2 job-site-card">
            <a href="https://www.linkedin.com/company/triple-crown-products-inc." target="_blank" rel="noopener noreferrer">
              <img 
                src={`${ImageUrl}files/14518-LinkedIn.svg`} 
                alt="LinkedIn" 
                className="job-site-logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
