import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiCall } from '../components/ApiCall';

const useContentById = (AdIds, RandomizeShowOrder = 0) => {
  const [ads, setAds] = useState([]);
  const [adLocationParts, setAdLocationParts] = useState([]);
  const [adParts, setAdParts] = useState([]);
  const [seo, setSeo] = useState([]);
  const Settings = useSelector((state) => state.siteWideSettings);

  useEffect(() => {
    const fetchAds = async () => {
      const AdIdsArray = AdIds.split(',').map(id => parseInt(id.trim()));

      const data = {
        AdLocationIDs: AdIds,
        UseDate: Settings?.activeDate,
        WebsiteID: 1,
        SelectedAttributes: "",
        ProductID: 0,
        SizeGroupID: 0,
        BrandID: 0,
        WebOrderID: 0,
        UrlPath: "index.aspx",
        RandomizeShowOrder: RandomizeShowOrder
      };

      try {
        const response = await ApiCall('tq_Ads_V9', data, 'sqlcon');
        const Ads = response?.data?.Table || [];
        const AdLocationParts = response?.data?.Table1 || [];
        const AdParts = response?.data?.Table2 || [];
        const Seo = response?.data?.Table3 || [];
        const filteredAds = Ads.filter(ad => AdIdsArray.includes(ad.AdLocationID));
        if (Settings?.imagerServer !== 'SERV12' && Settings?.imageServerUrl !== undefined && Settings?.imageServerUrl !== '') {
          filteredAds.forEach(ad => {
            ad.Html = ad.Html.replace(/https:\/\/remote\.max\.maxhat\.com\/images\//g, Settings?.imageServerUrl);
          });
        }

        setAds(filteredAds);
        setAdLocationParts(AdLocationParts);
        setAdParts(AdParts);
        setSeo(Seo);
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, [AdIds, Settings]);

  return { ads, adLocationParts, adParts, seo };
};

export default useContentById;
