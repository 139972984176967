import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../../styles/_SupportCustomer.scss";
import SendEmail from "../../components/SendEmail";
import DynamicTabs from "./DynamicTabs";
import useEmailAlertsSettings from "../../Hooks/useEmailAlertsSettings";

function SupportCustomer() {
  const Customer = useSelector(state => state?.customer);
  const [isSuccessful, setIsSuccessful] = useState("");
  const [message, setMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [showLinkWarning, setShowLinkWarning] = useState(false);
  const { emailTo, emailCc } = useEmailAlertsSettings(44);

  // Concatenate emails if there are multiple, otherwise use the single email
  //TODO to remember to update the fallback to Jade's email inside of ttps://remote.max.maxhat.com/TcpSalesCenterV2/SettingsAlerts before going live. 
  const fallbackRepEmailTo = emailTo.length > 1 ? emailTo.map(item => item.Email).join(', ') : (emailTo.length === 1 ? emailTo[0].Email : '');
  const fallbackRepEmailCc = emailCc.length > 1 ? emailCc.map(item => item.Email).join(', ') : (emailCc.length === 1 ? emailCc[0].Email : '');

  const handleSend = async (event) => {
    event.preventDefault();
    if (showSpinner || !message.trim()) {
      setInputError(true);
      return;
    }

    setShowSpinner(true);
    const toEmail = Customer?.SalesRep2Email || fallbackRepEmailTo;
    const ccEmail = Customer?.SalesRep2Email ? '' : fallbackRepEmailCc;

    const { results } = await SendEmail(toEmail, ccEmail, "Support Requested", message.trim(), Customer?.Email);
    setShowSpinner(false);
    setIsSuccessful(results === 'Success');
    if (results === 'Success') {
      setMessage("");
      setTimeout(() => setIsSuccessful(""), 5000);
    } else {
      setTimeout(() => setIsSuccessful(""), 5000);
    }
    setInputError(false);
  };

  // Function to remove URLs from text and show warning
  const sanitizeInput = (text) => {
    if (/https?:\/\/\S+/g.test(text)) {
      if (!showLinkWarning) {
        setShowLinkWarning(true);
        setTimeout(() => setShowLinkWarning(false), 5000);
      }
    }
    return text.replace(/https?:\/\/\S+/g, '');
  };

  return (
    <>
      <div className="tabsAndPages-wrapper">
        <DynamicTabs tabOverride="emailyoursalesassociate" />
      </div>
      <div className="support-wrapper pt-4">
        <form className="message-box-wrapper" onSubmit={handleSend}>
          <textarea
            className={`message-box ${inputError ? 'error' : ''}`}
            placeholder="Questions/Comments"
            maxLength="2000"
            value={message}
            onChange={(e) => {
              const sanitized = sanitizeInput(e.target.value);
              setMessage(sanitized);
              setInputError(false);
            }}
            disabled={showSpinner}
          ></textarea>
          <div className="send-btn-wrapper">
            {showSpinner && <i className="fa fa-spinner fa-spin spinner-size"></i>}
            <button
              type="submit"
              className={`universal-btn send-btn ${showSpinner ? 'disabled-btn' : ''}`}
              disabled={showSpinner}
            >
              SEND MESSAGE
            </button>
          </div>
        </form>
        {isSuccessful !== "" && (
          <div className="message-results-container">
            <div className={`${isSuccessful ? 'theme-success-message' : 'theme-failure-message'}`}>
              {isSuccessful ? "Message sent successfully!" : "Failed to send message."}
            </div>
          </div>
        )}
        {showLinkWarning && (
          <div className="message-results-container">
            <div className="theme-failure-message">
              Hyperlinks not allowed
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SupportCustomer;
