import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from 'react-icons/fa';
import ErrorBoundary from "../ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import useEmailFormatValidator from "../../Hooks/useEmailValidation";
import PasswordStrengthMeter from "../PasswordStrengthMeter";
import { ApiCall } from "../ApiCall";
import SendEmail from "../SendEmail";
import ReactDOMServer from 'react-dom/server';
import PasswordResetTemplate from '../EmailTemplates/PasswordReset';
import { updatePageMeta } from "../../Redux/siteWideSettingsSlice";

// pageName is provided by two differenct parents. use caution when updating the pageName prop. 
function PasswordReset({ pageName }) {
  const dispatch = useDispatch();
  const customerEmail = useSelector((state) => state?.customer?.email);
  const initialEmail = pageName === "security" ? customerEmail : '';
  const [email, setEmail] = useState(initialEmail);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({ email: "", password: "", confirmPassword: "" });
  const [resetRequested, setResetRequested] = useState(false);
  const [results, setResults] = useState('');
  const [resultsMessage, setResultsMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const getEmailValidationState = useEmailFormatValidator();
  const emailValidationState = getEmailValidationState(email);
  dispatch(updatePageMeta("Password Reset"));
  const handleResetPassword = async () => {
    setErrors({ email: "", password: "", confirmPassword: "" });

    // Reset border colors
    document.getElementById('email').style.border = '1px solid #ced4da';
    document.getElementById('newpassword').style.border = '1px solid #ced4da';
    document.getElementById('confirmNewPassword').style.border = '1px solid #ced4da';
    let isValid = true;
    const newErrors = {};

    if (!emailValidationState.isValid) {
      newErrors.email = emailValidationState.message;
      isValid = false;
    }
    if (newPassword.trim() === '') {
      newErrors.password = 'Password is required';
      isValid = false;
    }
    if (newPassword !== confirmNewPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      setLoading(true);
      setResetRequested(true);
      try {
        const data = {
          'Email': email,
          'NewPassword': newPassword
        }

        const response = await ApiCall('usp_Tcp_ResetPassword', data);
        setResults(response?.data?.Table[0]['Results']);
        setResultsMessage(response?.data?.Table[0]['ResultsMessage']);
        if (response?.data?.Table[0]['Results'] === 'Successful') {
          const EmailBody = ReactDOMServer.renderToString(
            <PasswordResetTemplate resetPasswordURL={window.location.origin + process.env.PUBLIC_URL + '/customer/passwordconfirmation/' + response?.data?.Table[0]['ResetRowGuid']} />
          );
          await SendEmail(email, '', 'TCP Password Reset Confirmation', EmailBody, 'noreply@triplecrownproducts.com');
        }
      } catch (error) {
        setResultsMessage('An error occurred while resetting your password');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ErrorBoundary componentName="ResetPassword" fallback={<ErrorMessage />}>
      <div className={pageName === "passwordreset" ? "forms-container positioning" : "security-forms-container"} >
        {pageName !== "security" && <h2>Password Reset</h2>}

        <div className="forms-box">
          {results === 'Successful' ? (
            <div className={`process-results ${results === 'Successful' ? 'process-success' : (resultsMessage ? 'process-error' : '')}`}>
              {results} {resultsMessage && `- ${resultsMessage}`}
            </div>
          ) : (
            <>
              {pageName === "passwordreset" &&
                <div className="input-group">
                  <label htmlFor="email" className="label">Email:</label>
                  <input
                    type="email"
                    disabled={resetRequested}
                    className={`input ${errors.email ? 'input-error' : ''}`}
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} autoComplete="username" />
                  {errors.email && <div className="error-message">{errors.email}</div>}
                </div>
              }

              <div className="input-group">
                <label htmlFor="newpassword">New Password:</label>
                <input
                  type="password"
                  id="newpassword"
                  placeholder="Enter your new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)} autoComplete="new-password"
                  disabled={resetRequested}
                  className={`${errors.password ? 'input-error' : ''}`}
                />
                {errors.password && <div className="error-message">{errors.password}</div>}
                <PasswordStrengthMeter password={newPassword} />
              </div>
              <div className="input-group">
                <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  placeholder="Confirm your new password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)} autoComplete="new-password"
                  disabled={resetRequested}
                  className={`${errors.confirmPassword ? 'input-error' : ''}`}
                />
                {errors.confirmPassword && <div className="error-message">{errors.confirmPassword}</div>}
              </div>
              <button
                type="button"
                disabled={loading}
                className="universal-btn LoginShell-buttons"
                onClick={handleResetPassword}
              >
                Reset Password
                {loading && (
                  <FaSpinner className="fa-spin" style={{ marginLeft: "5px", animation: "spin 1s linear infinite" }} />
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default PasswordReset;
