const CryptoJS = require("crypto-js");
const moment = require("moment");
const { v4: uuidv4 } = require("uuid");
// Uses the PBKDF2 algorithm to stretch the string 's' to an arbitrary size,
// in a way that is completely deterministic yet impossible to guess without
// knowing the original string

export function decryptObj(val) {
  var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_SECRET_KEY_128); // Convert into WordArray (using Utf8)
  var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV
  var decrypted = CryptoJS.AES.decrypt(val, key, { iv: iv }); // By default: CBC, PKCS7

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

//Cookie encryption

export function encryptString(val) {
  var ciphertext = CryptoJS.AES.encrypt(
    val,
    process.env.REACT_APP_SECRET_E_KEY
  ).toString();
  return ciphertext;
}

export function decryptString(val) {
  var bytes = CryptoJS.AES.decrypt(val, process.env.REACT_APP_SECRET_E_KEY);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

//New Crypt js file

export function cryptAPIKey(val) {
  const nonce = uuidv4().toString('N');
  var key = process.env.REACT_APP_SECRET_E_KEY;
  var iv = process.env.REACT_APP_SECRET_IV;
  val = [nonce, val, moment().unix()].join(':')

  let cipher = CryptoJS.AES.encrypt(val, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  return cipher.toString();
}

export function decryptUsingAES256(val) {
  var key = process.env.REACT_APP_SECRET_KEY_128;
  var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);

  let decrypted = CryptoJS.AES.decrypt(val, 
    CryptoJS.enc.Utf8.parse(key), 
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }
  )

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function encryptUsingAES256(val) {
  var key = process.env.REACT_APP_SECRET_E_KEY;
  var iv = process.env.REACT_APP_SECRET_IV;

  let cipher = CryptoJS.AES.encrypt(val, 
    CryptoJS.enc.Utf8.parse(key), 
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }
  );

  return cipher.toString();
}

export function encryptUsingAES256Base64(val) {
  var key = process.env.REACT_APP_SECRET_E_KEY;
  var iv = process.env.REACT_APP_SECRET_IV;

  let cipher = CryptoJS.AES.encrypt(val, 
    CryptoJS.enc.Utf8.parse(key), 
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }
  );

  let base64CipherText = CryptoJS.enc.Base64.stringify(cipher.ciphertext);
  return base64CipherText;
}

export function decryptUsingAES256Base64(encryptedBase64) {
  var key = process.env.REACT_APP_SECRET_E_KEY;
  var iv = process.env.REACT_APP_SECRET_IV;

  let ciphertext = CryptoJS.enc.Base64.parse(encryptedBase64);

  let decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: ciphertext
    },
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
}