import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faComment, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  postMfaTotp, postSendEmailCode, postSendSmsCode, postVerifySmsCode
} from '../../components/Security/Mfa';
import { ApiCall } from '../../components/ApiCall';
import { encryptUsingAES256Base64 } from '../../components/Security/crypt';
import { setMfa } from '../../Redux/customerSlice';
import '../../styles/MfaLogin.scss';

function LoginMfa() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector((state) => state?.SiteWideSettings?.User);
  const [Notification, setNotification] = useState([]);
  const [Method, setMethod] = useState('');
  const [Code, setCode] = useState('');
  const MfaAuthenticator = 'Authenticator';
  const MfaSms = 'Sms';
  const MfaCall = 'Call';
  const MfaEmail = 'Email';

  const handleLogin = async (method) => {
    if (method === MfaAuthenticator) {

    }
    else if (method === MfaSms) {
      setMethod(MfaSms);
      const { results, resultsMessage } = await dispatch(postSendSmsCode('+1' + User?.CellPhone))

      if (results === 'Successful') {
        UpdateNotification('CodeSent', 'Successful')
      } else {
        UpdateNotification('CodeSent', 'Failed')
      }

    }
    else if (method === MfaCall) {
      setMethod(MfaCall);
    }
    else if (method === MfaEmail) {
      setMethod(MfaEmail);
      UpdateNotification('CodeSent', 'Sending')

      const { results, resultsMessage } = await dispatch(postSendEmailCode(User?.Email))

      if (results === 'Successful') {
        UpdateNotification('CodeSent', 'Successful')
      } else {
        UpdateNotification('CodeSent', 'Failed')
      }
    }
  };

  const UpdateNotification = (Section, SectionValue) => {
    setNotification((prevNotification) => ({
      ...prevNotification,
      [Section]: SectionValue
    }));
  }

  const handleCode = async () => {
    if (Method === MfaAuthenticator) {

    }
    else if (Method === MfaSms) {
      const { results, resultsMessage } = await dispatch(postVerifySmsCode('+1' + User?.CellPhone, Code));

      if (results === 'Successful' || results === 200) {
        UpdateNotification(MfaSms + 'CodeVerify', 'Successful');
        MfaPass('Successful');
      } else {
        UpdateNotification(MfaSms + 'CodeVerify', 'Failed');
        return;
      }
    }
    else if (Method === MfaCall) {

    }
    else if (Method === MfaEmail) {
      var data = {
        'RequestType': 'Verify',
        'Email': User?.Email,
        'CodeToVerify': Code
      }
      const response = await ApiCall('usp_UserProfile_MfaEmail', data)
      const results = response?.data?.Table[0]["Results"];

      if (results === 'Successful') {
        UpdateNotification(MfaEmail + 'CodeVerify', 'Successful');
        MfaPass(results);
      } else {
        UpdateNotification(MfaEmail + 'CodeVerify', 'Failed');
        return;
      }
    }
  };

  const MfaPass = (results) => {
    dispatch(setMfa(results));
    navigate('/');
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className='mb-5'>
          <h1>Verify Your Identity</h1>
        </div>
        {Method === '' && (
          <div className="col-md-6 bg-light p-4 rounded shadow">
            {User?.MfaAuthenticator && (
              <div className={`authentication-tile`} onClick={() => handleLogin(MfaAuthenticator)}>
                <FontAwesomeIcon icon={faQrcode} className="me-2" />
                <span>Approve a request on Authenticator app</span>
              </div>
            )}
            {User?.MfaSms && (
              <div className={`authentication-tile`} onClick={() => handleLogin(MfaSms)}>
                <FontAwesomeIcon icon={faComment} className="me-2" />
                <span>Text +X XXX XXX XX{User?.CellPhone && User?.CellPhone.slice(-2)}</span>
              </div>
            )}
            {/* Phone call code disabled */}
            {User?.MfaSms && 1 === 0 && (
              <div className={`authentication-tile`} onClick={() => handleLogin(MfaCall)}>
                <FontAwesomeIcon icon={faComment} className="me-2" />
                <span>Call +X XXX XXX XX{User?.CellPhone && User?.CellPhone.slice(-2)}</span>
              </div>
            )}
            {User?.MfaEmail && (
              <div className={`authentication-tile`} onClick={() => handleLogin(MfaEmail)}          >
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                <span>Email {User?.Email && User?.Email.substring(0, 3) + "*****" + User?.Email.substring(User?.Email.lastIndexOf("@"))}</span>
                <br />
              </div>
            )}
            {!User?.MfaAuthenticator && !User?.MfaSms && !User?.MfaEmail && (
              <div>
                {User ? (
                  <div>You don't have any Multi-Factor Authentication methods set up. <Link to={"/UserProfile"}>Setup MFA</Link></div>
                ) : (
                  <div>You need to be logged in to set up Multi-Factor Authentication.</div>
                )}
              </div>
            )}
          </div>
        )}
        {Method !== '' && (
          <div className="col-md-6 bg-light p-4 rounded shadow notification-container">
            {Notification?.CodeSent === 'Sending' && (
              <span className='mfaSending'>Sending Code, please wait...</span>
            )}
            {Notification?.CodeSent === 'Successful' && (
              <div>
                <div>
                  <span className='mfaSuccessful'>Code sent to your email</span>
                </div>
                <div className='mfaCodeContainer'>
                  <input
                    type="text"
                    className="mfaCode"
                    placeholder="Enter Code"
                    value={Code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button className="btn btn-primary" onClick={handleCode}>Submit</button>
                </div>
              </div>
            )}
            {Notification?.CodeSent === 'Failed' && (
              <span className='mfaFailed'>Code failed to send please try again later</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginMfa;
