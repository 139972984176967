import React, { useState, useEffect } from "react";
import DynamicTabs from "./DynamicTabs";
import { useSelector } from "react-redux";
import { ApiCall } from "../ApiCall";
import Loading from "../loading";

function SavedCarts() {
  const [Carts, setCarts] = useState([]);
  const customerProfile = useSelector(state => state?.customer);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSavedCarts = async () => {
      setIsLoading(true);
      var data = {
        'OrderType': 'Carts',
        'WebsiteId': '1',
        'WebsiteUserId': customerProfile?.websiteUserID,
        'CustID': customerProfile?.account
      }
      const response = await ApiCall('usp_Webstore_Orders', data, 'Tcp');
      setCarts(response?.data?.Table);
      setIsLoading(false);
    };
    fetchSavedCarts();
  }, [customerProfile?.websiteUserID, customerProfile?.account])

  return (
    <>
      <DynamicTabs />
      {isLoading && <Loading />}
      {!isLoading && Carts.length > 0 && (
        <>
          {Carts.map((Cart, index) => (
            <div key={index}>
              <div>{Cart.WebOrderID}</div>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default SavedCarts;