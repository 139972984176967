import { decryptUsingAES256Base64 } from './crypt';

const getStorageArrayValue = (storageKey, arrayKey, defaultValue = '') => {
  let encryptedValue = localStorage.getItem(storageKey) || sessionStorage.getItem(storageKey);

  if (!encryptedValue) {
    return defaultValue;
  } else {
    try {
      const decryptedValue = decryptUsingAES256Base64(encryptedValue);
      const storedArray = JSON.parse(decryptedValue) || {};
      const value = storedArray[arrayKey];
      return value !== undefined ? value : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }
}

export default getStorageArrayValue;
